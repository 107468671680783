<template>
  <div :class="[{root: true, sidebarClose, sidebarStatic}, 'dashboard']">
    <Sidebar/>
    <div class="wrap">
      <Header/>
      <v-touch :swipe-options="{direction: 'horizontal',threshold: 80}"
               class="content"
               @swipeleft="handleSwipe"
               @swiperight="handleSwipe"
      >
        <router-view/>
        <footer class="contentFooter">
          <a class="dptblc tal" href="https://google.co.jp/" title="株式会社フューチャーテックラボ">
            <span class="copyright_logo"><span class="path1"></span><span
                class="path2"></span><span class="path3"></span><span class="path4"></span><span
                class="path5"></span><span class="path6"></span><span class="path7"></span><span
                class="path8"></span><span class="path9"></span><span class="path10"></span><span class="path11"></span><span
                class="path12"></span><span class="path13"></span><span class="path14"></span><span
                class="path15"></span><span class="path16"></span><span class="path17"></span><span
                class="path18"></span><span class="path19"></span><span class="path20"></span></span>
          </a><span class="dptblc tar vabt">(C) Futuretechlabs, Inc.</span>
        </footer>
      </v-touch>
    </div>
  </div>
</template>


<script>
import {mapActions, mapState} from 'vuex';

import Sidebar from '@/components/Sidebar/Sidebar';
import Header from '@/components/Header/Header';
import './Layout.scss';

export default {
  name: 'Layout',
  components: {Sidebar, Header},
  methods: {
    ...mapActions(
        'layout', ['switchSidebar', 'handleSwipe', 'changeSidebarActive'],
    ),
    swipe(direction) {
      this.swipeDirection = direction
    },
  },
  data: () => ({
    swipeDirection: 'None',
  }),
  computed: {
    ...mapState('layout', {
      sidebarClose: state => state.sidebarClose,
      sidebarStatic: state => state.sidebarStatic,
    }),
  },
  created() {
    const staticSidebar = JSON.parse(localStorage.getItem('sidebarStatic'));

    if (staticSidebar) {
      this.$store.state.layout.sidebarStatic = true;
    } else if (!this.sidebarClose) {
      setTimeout(() => {
        this.switchSidebar(true);
        this.changeSidebarActive(null);
      }, 2500);
    }
  },
};
</script>

<style lang="scss" src="./Layout.scss"/>
