var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('nav',{class:{sidebar: true, sidebarStatic: _vm.sidebarStatic, sidebarOpened: _vm.sidebarOpened},on:{"mouseenter":_vm.sidebarMouseEnter,"mouseleave":_vm.sidebarMouseLeave}},[_c('header',{staticClass:"logo"},[_c('router-link',{attrs:{"to":"/app/dashboard"}},[_c('strong',[_c('span',{staticClass:"primary-word"},[_vm._v("SOC")]),_c('span',{staticClass:"secondary-word"},[_vm._v(" Analyst")])])])],1),_c('div',{staticClass:"nav"},[_c('ul',[_c('NavLink',{attrs:{"header":"ダッシュボード","iconName":"flaticon-home","index":"dashboard","isHeader":"","link":"/app/dashboard"}}),_c('NavLink',{attrs:{"activeItem":_vm.activeItem,"childrenLinks":[{ header: 'アラート一覧(CrowdStrike)',
                             isSpecial: true,
                             link: '/app/alert/alerts',
                             special: this.count_new,
                             },
                             { header: 'アラート一覧(Sophos)',
                             isSpecial: false,
                             link: '/app/alert/alert-sophos'
                             },
                             { header: 'メールテンプレート', link: '/app/alert/email-template' } ],"header":"アラート情報","iconName":"flaticon-alarm","index":"components","link":"/app/components"}}),_c('NavLink',{attrs:{"activeItem":_vm.activeItem,"childrenLinks":[
                          { header: 'IOA Exclusions', link: '/app/exclusions/ioa-exclusion' },
                          { header: 'Sensor-Visibility Exclusions', link: '/app/exclusions/sensor-visibility' },
                          { header: 'Machine-Learning Exclusions', link: '/app/exclusions/machine-learning' } ],"header":"除外リスト","iconName":"flaticon-skip","index":"components","link":"/app/components"}}),_c('NavLink',{attrs:{"activeItem":_vm.activeItem,"childrenLinks":[
        { header: '顧客登録', link: '/app/integration/customer' }   ,
        { header: 'API登録', link: '/app/integration/api' }   ],"header":"API連携","iconName":"flaticon-network","index":"components","link":"/app/components"}}),(_vm.role==='1')?_c('NavLink',{attrs:{"activeItem":_vm.activeItem,"childrenLinks":[
        { header: 'ユーザ一覧', link: '/app/admin/user-manage' },
        { header: '実績', link: '/app/admin/achievement' }
          ],"header":"ユーザ管理","iconName":"flaticon-user","index":"components","link":"/app/components"}}):_vm._e(),_c('NavLink',{attrs:{"activeItem":_vm.activeItem,"childrenLinks":[
        { header: 'パスワード変更', link: '/app/profile/change-password' } ],"header":"プロファイル","iconName":"flaticon-settings","index":"components","link":"/app/components"}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }