<template>

  <b-navbar class="header d-print-none  sticky-top " variant="faded">
    <b-nav>
      <b-nav-item>
        <a id="barsTooltip" class="d-md-down-none px-2" href="#" @click="toggleSidebarMethod">
          <i class='la la-bars la-lg'/>
        </a>
        <b-tooltip placement="bottom" target="barsTooltip">
          <!--  Turn on/off <br> sidebar <br> collapsing -->
        </b-tooltip>
        <a class="fs-lg d-lg-none" href="#" @click="switchSidebarMethod">
          <span class="rounded rounded-lg bg-gray text-white d-md-none">
            <i class="la la-bars la-lg"/>
          </span>
          <i class="la la-bars la-lg d-sm-down-none ml-4"/>
        </a>
      </b-nav-item>
    </b-nav>

    <b-nav class="ml-auto">
      <b-nav-item>
        <template>
              <span class="avatar thumb-sm float-left mr-2">
                 <img class="rounded-circle" :src="image_path()" alt=""/>
              </span>
          <span>
            <b-badge variant="light">{{ username }}</b-badge>
          </span>
          <span>
            <b-badge variant="light">last login: {{current_login_at}} </b-badge>
          </span>
        </template>
      </b-nav-item>

      <b-nav-item-dropdown class="settingsDropdown d-sm-down-none" justify-content-end no-caret>
        <template slot="button-content">
          <i class="la la-cog px-2"/>
          <em>設定</em>
        </template>

        <!--<b-dropdown-item><i class="la la-user" />アカウント情報</b-dropdown-item> -->
        <!-- <b-dropdown-item><i class="la la-calendar" />カレンダー</b-dropdown-item> -->

        <b-dropdown-divider/>
        <b-dropdown-item-button @click="onLogout">
          <i class="la la-sign-out"/> ログアウト
        </b-dropdown-item-button>
      </b-nav-item-dropdown>
    </b-nav>
  </b-navbar>

</template>

<script>
import $ from 'jquery'
import {mapActions, mapState} from 'vuex'
import dayjs from 'dayjs'

export default {
  data: function () {
    return {
      username: '',
      current_login_at:''
    }
  },
  name: 'Headed',
  computed: {
    ...mapState('layout', {
      sidebarClose: state => state.sidebarClose,
      sidebarStatic: state => state.sidebarStatic,
    }),
  },
  methods: {
    ...mapActions('layout', ['toggleSidebar', 'toggleChat', 'switchSidebar', 'changeSidebarActive']),
    ...mapActions('auth', ['logout']),
    ...mapActions('auth', ['logout']),

    onLogout: function () {
      let self = this
      self.logout({
        router: self.$router
      })
    },
    image_path: function(){
      let path =  "../../assets/logo/" + this.serviceNo + ".svg"
      return path
    },
    switchSidebarMethod() {

      if (!this.sidebarClose) {
        this.switchSidebar(true);
        this.changeSidebarActive(null);
      } else {
        this.switchSidebar(true);
        const paths = this.$route.fullPath.split('/');
        paths.pop();
        this.changeSidebarActive(paths.join('/'));
      }
    },
    toggleSidebarMethod() {
      if (this.sidebarStatic) {
        this.toggleSidebar();
        this.changeSidebarActive(null);
      } else {
        this.toggleSidebar();
        const paths = this.$route.fullPath.split('/');
        paths.pop();
        this.changeSidebarActive(paths.join('/'));
      }
    }
  },
  created() {
    if (window.innerWidth > 576) {
      setTimeout(() => {
        const $chatNotification = $('#chat-notification');
        $chatNotification.removeClass('hide').addClass('animated fadeIn')
            .one('webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend', () => {
              $chatNotification.removeClass('animated fadeIn');
              setTimeout(() => {
                $chatNotification.addClass('animated fadeOut')
                    .one('webkitAnimationEnd mozAnimationEnd MSAnimationEnd'
                        + ' oanimationend animationend', () => {
                      $chatNotification.addClass('hide');
                    });
              }, 6000);
            });
        $chatNotification.siblings('#toggle-chat')
            .append('<i class="chat-notification-sing animated bounceIn"></i>');
      }, 4000);
    }
  },
  mounted() {
    // eslint-disable-next-line no-irregular-whitespace
    this.username = window.sessionStorage.getItem("username")
    this.current_login_at = dayjs().format('YYYY/MM/DD HH:mm:ss')
},
};
</script>

<style lang="scss" scoped src="./Header.scss"/>
