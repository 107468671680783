<template>
  <div>
    <b-breadcrumb>
      <b-breadcrumb-item>アラート情報</b-breadcrumb-item>
      <b-breadcrumb-item active>メールテンプレート管理</b-breadcrumb-item>
    </b-breadcrumb>

    <b-row>
      <b-col>
        <div>
          <Widget name="select" class="mx-auto" title="<h3 class='mt-0 center'></h3>" customHeader>
            <b-alert v-if="errorMessage!==''" show :variant="messageColor" class="m-8"><h4>{{ errorMessage }}</h4></b-alert>
            <form @submit.prevent="onSubmit">
              <b-container fluid>
                <b-row class="my-1">
                  <b-col sm="1">
                    <label for="input-none">件名:</label>
                  </b-col>
                  <b-col sm="11">
                    <b-form-input id="subject" v-model="detailItem.subject" required></b-form-input>
                    </b-col>
                </b-row>

                <b-row class="my-1">
                  <b-col sm="1">
                    <label for="input-valid">本文:</label>
                  </b-col>
                  <b-col sm="11">
                    <tinymce id="article" v-model="detailItem.article" :other_options="options"></tinymce>

                  </b-col>
                </b-row>
                <b-row></b-row>
                <b-row class="my-1">
                  <b-col sm="2">
                    <label for="input-invalid"></label>
                  </b-col>
                  <b-col sm="10">
                    <b-button type="submit"   variant="success" class="p-1 px-3 btn-xs"  > 更新 </b-button>
                  </b-col>
                </b-row>
              </b-container>

            </form>
          </Widget>
        </div>
      </b-col></b-row>
  </div>
</template>

<script>

import Widget from '@/components/Widget/Widget'
import api from "@/api"

export default {
  name: 'EmailTemplate',
  components: { Widget },
  computed: {
    visibleFields() {
      return this.fields.filter(field => field.visible)
    },

  },
  data () {
    return {
      options: {
        //language_url: "/dist/static/js/langs/ja.js",
        //language_url: "/static/js/langs/ja.js",
        //language: "ja",
        //content_css: "/tinymce/skins/content/default/content.css",
        height: 500,
        resize: false,
        branding: false,
       },
      detailItem: {},
      errorMessage: "",
      messageColor: "",
    }
  },
  created () {
    this.errorMessage=''

    api.post('/api/template/init', {}).then (res => {

      console.log(res.data);
      let status =res.data.statusCode
      let result =res.data.body.result

      if (status==200 && result=='success'){
        this.detailItem = res.data.body.data['detailItem']

     }
      if (status==200 && result=='failure'){
        this.errorMessage=res.data.body.error
        this.messageColor="success"
      }
    })
        .catch(error => {
          console.log(error)
          this.errorMessage=error
          this.messageColor="danger"
        })

  },
  mounted() {
    // Set the initial number of items
    this.errorMessage=''


  },
  methods: {
    onSubmit() {
      this.errorMessage = ""
      const id = this.detailItem.id
      event.preventDefault()
      api.put('/api/template/update/' + id, {
        subject: this.detailItem.subject,
        article: this.detailItem.article,

      }).then (res => {

        console.log(res.data)
        let status =res.data.statusCode
        let result =res.data.body.result

        if (status==200 && result=='success'){
          this.$nextTick(() => {
            this.detailItem = res.data.body.data.detailItem
            this.errorMessage = "正常に更新しました。"
            this.messageColor = "success"

          })
        }
        if (status==200 && result=='failure'){
          this.errorMessage=res.data.body.error
          this.messageColor="warning"
        }
      }).catch(error => {
          console.log(error)
          this.errorMessage=error
          this.messageColor="danger"

      })
    },

  },
}
</script>

<style src="./EmailTemplate.scss" lang="scss" scoped />
