<template>
  <div>
    <b-breadcrumb>
      <b-breadcrumb-item>API連携</b-breadcrumb-item>
      <b-breadcrumb-item active>顧客登録</b-breadcrumb-item>
    </b-breadcrumb>
    <b-row>
      <b-col xs="12">
        <Widget
            bodyClass="widget-table-overflow"
            customHeader
            title="<h5> <span class='fw-semi-bold'> </span></h5>"
        >
          <div class="table-responsive">
            <b-table
                ref="selectableTable"
                :current-page="currentPage"
                :fields="visibleFields"
                :items="allItems"
                :per-page="perPage"
                :select-mode="selectMode"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                head-variant="light"
                outlined
                responsive="lg"
                selectable
                selected-variant="success"
                show-empty
                style="width:100%"
                @filtered="onFiltered"
                @row-clicked="onRowSelected"
            >
              <template #cell(selected)="{ rowSelected }">
                <template v-if="rowSelected">
                  <strong><span aria-hidden="true" style="color:#000000;"><h4>&check;</h4></span></strong>
                  <span class="sr-only">Selected</span>
                </template>
                <template v-else>
                  <span aria-hidden="true">&nbsp;</span>
                  <span class="sr-only">Not selected</span>
                </template>
              </template>
              <template #cell(name)="row">
                <template v-if="row.value.length > 20">
                  <span v-b-popover.hover.focus="row.value">{{ row.value.substring(0, 20) }}...</span>
                </template>
                <template v-else>
                  <span>{{ row.value }}</span>
                </template>
              </template>
              <template #cell(vendor)="row">
                <template v-if="row.value.length > 20">
                  <span v-b-popover.hover.focus="row.value">{{ row.value.substring(0, 20) }}...</span>
                </template>
                <template v-else>
                  <span>{{ row.value }}</span>
                </template>
              </template>
              <template #cell(contract_type)="row">
                <template v-if="row.value.length > 20">
                  <span v-b-popover.hover.focus="row.value">{{ row.value.substring(0, 20) }}...</span>
                </template>
                <template v-else>
                  <span>{{ row.value }}</span>
                </template>
              </template>
              <template #cell(status)="row">
                <b-button :variant="statusColor[row.value]" class="p-1 px-3 btn-xs" style="width: 80px">
                  {{ statusText[row.value] }}
                </b-button>
              </template>
              <template #cell(owner)="row">
                <template v-if="row.value.length > 20">
                  <span v-b-popover.hover.focus="row.value">{{ row.value.substring(0, 20) }}...</span>
                </template>
                <template v-else>
                  <span>{{ row.value }}</span>
                </template>
              </template>
              <template #cell(delete)={rowSelected}>
                <template v-if="rowSelected">
                  <strong><span aria-hidden="true" style="color:#ff0000;"><a
                      @click="userDelete"><h4>&chi;</h4></a></span></strong>
                  <span class="sr-only">Selected</span>
                </template>
                <template v-else>
                  <span aria-hidden="true">&nbsp;</span>
                  <span class="sr-only">Not selected</span>
                </template>
              </template>
            </b-table>
          </div>
          <b-row>
            <b-col class="pb-2" lg="1"></b-col>
            <b-col class="pb-2" lg="4">
              <b-btn @click="addDetail">
                <b-icon icon="person-plus-fill"></b-icon>
              </b-btn>
            </b-col>
            <b-col class="pb-2" lg="3">
              <b-pagination
                  v-model="currentPage"
                  :per-page="perPage"
                  :total-rows="totalRows"
                  align="left"
                  class="mx-5"
                  size="md"
                  @page-click="initDetailItem"
              ></b-pagination>
            </b-col>
            <b-col class="pb-2" lg="4">
              <div class="left">
                <b-form-group
                    label=""
                    label-align="left"
                    label-for="per-page-select"
                    style="margin-left:70%;width:30%"
                >
                  <b-form-select
                      id="per-page-select"
                      v-model="perPage"
                      :options="pageOptions"
                      @change="initDetailItem"
                  >
                  </b-form-select>
                </b-form-group>
              </div>
            </b-col>
          </b-row>
        </Widget>
      </b-col>
    </b-row>


    <b-row>
      <b-col>
        <div v-if="isShowAll">
          <Widget class="mx-auto" customHeader name="select" title="<h3 class='mt-0 center'></h3>">
            <b-alert v-if="errorMessage!==''" :variant="messageColor" class="m-8" show><h4>{{ errorMessage }}</h4>
            </b-alert>
            <v-container v-if="isShow" fluid>

              <v-card>
                <v-form ref="addForm" v-model="isValid">
                  <v-row>
                    <v-col cols="2">
                      <v-subheader>顧客名</v-subheader>
                    </v-col>
                    <v-col cols="8">
                      <v-text-field
                          ref="name"
                          v-model="detailItem.name"
                          :rules="nameRules"
                          filled
                          placeholder="顧客名"
                      >
                        <template v-slot:label>
                          <span style="color: red"> *</span>
                        </template>
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="2">
                      <v-subheader>契約タイプ</v-subheader>
                    </v-col>
                    <v-col cols="8">
                      <v-text-field
                          ref="contract_type"
                          v-model="detailItem.contract_type"
                          :rules="contractTypeRules"
                          filled
                          label=""
                          placeholder="契約タイプ"
                      >
                        <template v-slot:label>
                          <span style="color: red"> *</span>
                        </template>
                      </v-text-field>
                    </v-col>
                  </v-row>

                  <v-row v-if="detailItem.memberList.length === 0">
                    <v-col cols="2">
                      <v-subheader>担当者</v-subheader>
                    </v-col>
                    <v-col cols="8">
                      担当者なし
                    </v-col>
                    <v-col cols="2">
                      <v-btn
                          class="ma-2"
                          color="grey"
                          dark
                          small
                          @click="addInput(0)"
                      >
                        <v-icon dark>mdi-plus</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row v-for="member in detailItem.memberList" :key="member.id">
                    <v-col cols="2">
                      <v-subheader>担当者 {{ member.id + 1 }}</v-subheader>
                    </v-col>
                    <v-col cols="2">
                      <v-text-field
                          v-model="member.last_name"
                          :rules="[required, limit_length]"
                          filled
                          label=""
                          placeholder="氏名"
                          suffix=""
                      >
                        <template v-slot:label>
                          <span style="color: red"> *</span>
                        </template>
                      </v-text-field>
                    </v-col>
                    <v-col cols="2">
                      <v-text-field
                          v-model="member.first_name"
                          :rules="[required, limit_length]"
                          filled
                          placeholder="名前"
                          suffix=""
                      >
                        <template v-slot:label>
                          <span style="color: red"> *</span>
                        </template>
                      </v-text-field>
                    </v-col>
                    <v-col cols="2">
                      <v-text-field
                          v-model="member.email"
                          :rules="emailRules"
                          filled
                          placeholder="メールアドレス"
                          suffix=""
                      >
                        <template v-slot:label>
                          <span style="color: red"> *</span>
                        </template>
                      </v-text-field>
                    </v-col>
                    <v-col cols="2">
                      <v-text-field
                          v-model="member.telNo"
                          filled
                          placeholder="電話番号"
                          suffix=""
                      >
                        <template v-slot:label>
                          <span style="color: red"></span></template>
                      </v-text-field>
                    </v-col>
                    <v-col>
                      <v-btn
                          v-if="detailItem.memberList.length < 5"
                          class="ma-2"
                          color="grey"
                          dark
                          small
                          @click="addInput(member.id)"

                      >
                        <b-icon icon="plus-lg"></b-icon>
                      </v-btn>
                    </v-col>
                    <v-col>
                      <v-btn
                          v-if="detailItem.memberList.length > 1"
                          class="ma-2"
                          color="grey"
                          dark
                          small
                          @click="removeInput(member.id)"
                      >
                        <b-icon icon="dash-lg"></b-icon>
                      </v-btn>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="2">
                      <v-subheader>ベンダー</v-subheader>
                    </v-col>
                    <v-col cols="2">
                      <b-form-select
                          id="per-page-select"
                          v-model="detailItem.vendor"
                          :options="vendorOptions2"
                          return-object
                      >
                      </b-form-select>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="2">
                      <v-subheader>メモ</v-subheader>
                    </v-col>
                    <v-col cols="10">
                      <v-textarea ref="name"
                                  v-model="detailItem.memo"
                                  :rules="memoRules"
                                  filled
                                  placeholder="メモ"
                      ></v-textarea>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="2">
                      <v-subheader>ステータス</v-subheader>
                    </v-col>
                    <v-col cols="10">
                      <v-card-text>
                        <v-radio-group
                            v-model="detailItem.status"
                            row
                        >
                          <v-radio
                              active-class="is-active"
                              color="#009999"
                              label="有効"
                              value="0"
                          ></v-radio>
                          <v-radio
                              active-class="is-active"
                              color="#a5203f"
                              label="無効"
                              value="1"
                          ></v-radio>
                        </v-radio-group>
                      </v-card-text>
                    </v-col>
                  </v-row>
                </v-form>
                <v-card-actions>
                  <v-row justify="center">
                    <v-col cols="5">
                    </v-col>
                    <v-col>
                      <b-button :disabled="!isValid" class="p-1 px-3 btn-xs" type="submit" variant="success"
                                @click="onSubmit">{{ formTittle }}
                      </b-button>
                    </v-col>
                  </v-row>
                </v-card-actions>
              </v-card>
            </v-container>
          </Widget>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Widget from '@/components/Widget/Widget'
import api from "@/api"

export default {
  name: "CustomerRegister",
  components: {Widget},
  computed: {
    visibleFields() {
      return this.fields.filter(field => field.visible)
    },

  },
  data() {
    return {
      isValid: false,
      lIndex: 0,
      statusColor: ['warning', 'secondary'],
      statusText: ['有効', '無効'],
      selectMode: 'single',//'multi'
      selected: {},
      sortBy: 'id',
      sortDesc: true,
      allItems: [],
      fields: [
        {key: 'selected', label: '', visible: true, thStyle: {width: "0px"},},
        {key: 'id', label: '#', sortable: true, class: 'text-center', visible: false, thStyle: {width: "0px"},},//PK
        {key: 'name', label: '顧客名', sortable: true, class: 'text-center', visible: true},
        {key: 'vendor', label: 'ベンダー', sortable: true, class: 'text-center', visible: true},
        {key: 'contract_type', label: '契約タイプ', sortable: true, class: 'text-center', visible: true},
        {key: 'department', label: '部署', sortable: true, class: 'text-center', visible: false},
        {key: 'memo', label: 'メモ', sortable: true, class: 'text-center', visible: false},
        {key: 'owner', label: '担当者', sortable: true, class: 'text-center', visible: true},
        {key: 'first_name', label: '名前', sortable: true, class: 'text-center', visible: false},
        {key: 'last_name', label: '氏名', sortable: true, class: 'text-center', visible: false},
        {key: 'email', label: 'メールアドレス', sortable: true, class: 'text-center', visible: true},
        {key: 'telNo', label: '電話', sortable: true, class: 'text-center', visible: true},
        {key: 'update_at', label: '更新日時', sortable: true, class: 'text-center', visible: true},
        {key: 'status', label: 'ステータス', sortable: true, class: 'text-center', visible: true,},
        {key: 'delete', label: '', visible: true, thStyle: {width: "0px"},},
      ],

      vendorOptions: [
        {selectedValue: 'crowdstrike', showLabel: 'crowdstrike'},
        {selectedValue: 'sophos', showLabel: 'sophos'},
      ],
      vendorOptions2: ['crowdstrike',
        'sophos'
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 20, 30, {value: 100, text: "Show a lot"}],
      formTittle: '',
      detailItem: {},
      newName: '',
      isShow: false,
      isShowAll: false,
      errorMessage: "",
      messageColor: "",
      requireRule: [
        v => !!v || '必須',
      ],
      nameRules: [
        v => !!v || '必須',
        v => v.length <= 50 || '50文字以内で入力してください'
      ],
      contractTypeRules: [
        v => !!v || '必須',
        v => v.length <= 128 || '128文字以内で入力してください'
      ],
      emailRules: [
        v => !!v || '必須',
        v => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || '無効なメールアドレス'
      ],
      telNoRules: [
        v => !!v || '必須',
        v => /^(0[5-9]0[0-9]{8}|0[1-9][1-9][0-9]{7})$/.test(v.replace(/[━.*‐.*―.*－.*\-.*ー.]/gi, '')) || '電話番号の形式が正しくありません'
      ],
      departmentRules: [
        v => v.length <= 50 || '50文字以内で入力してください'
      ],
      memoRules: [
        v => v.length <= 128 || '128文字以内で入力してください'
      ],
      ownerRules: [
        v => !!v || '必須',
        v => v.length <= 20 || '20文字以内で入力してください'
      ],
      required: value => !!value || "必須",
      limit_length: value => value.length <= 10 || "20文字以内で入力してください"
    }
  },

  mounted() {
    this.errorMessage = ''
    this.allItems = []
    this.isShow = false
    this.isShowAll = false
    api.post('/api/customer/list', {}).then(res => {

      //console.log(res.data)
      let status = res.data.statusCode
      let result = res.data.body.result

      if (status === 200 && result === 'success') {
        let iData = res.data.body.data['allItems']
        let itemsTemp = []
        for (let i = 0; i < iData.length; i++) {
          itemsTemp.push({
            id: iData[i].id,
            name: iData[i].name,
            memberList: iData[i].memberList,
            vendor: iData[i].vendor,
            status: iData[i].status,
            contract_type: iData[i].contract_type,
            memo: iData[i].memo,
            update_at: iData[i].update_at,
            email: iData[i].memberList[0].email,
            telNo: iData[i].memberList[0].telNo,
            last_name: iData[i].memberList[0].last_name,
            first_name: iData[i].memberList[0].first_name,
            owner: iData[i].memberList[0].last_name + iData[i].memberList[0].first_name,
          });
        }
        this.allItems = itemsTemp
        this.totalRows = this.allItems.length

      }
      if (status === 200 && result === 'failure') {
        this.errorMessage = res.data.body.error
        this.messageColor = "warning"
      }
    })
        .catch(error => {
          console.log(error)
          this.errorMessage = error
          this.messageColor = "danger"
        })

  },

  methods: {
    initDetailItem() {
      this.errorMessage = ""
      this.lIndex = 0

    },

    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
      this.initDetailItem()
      this.$nextTick(() => {
        this.detailItem = {}
      })

    },
    onRowSelected(item, index) {
      let oldItem = {}
      this.detailItem = {}
      this.selected = item
      this.initDetailItem()
      this.formTittle = '更新'
      this.$nextTick(() => {
        oldItem = this.allItems.find(v => v.id === item.id)
        this.detailItem = {
          id: oldItem.id,
          name: oldItem.name,
          status: oldItem.status,
          contract_type: oldItem.contract_type,
          memo: oldItem.memo,
          vendor: oldItem.vendor,
          memberList: oldItem.memberList
        }
        this.lIndex = index
        this.isShow = true
        this.isShowAll = true
        this.newName = oldItem.name
        this.$nextTick(() => this.$refs.name.focus())

      })
    },
    // 入力欄追加
    addInput(id) {
      let inputList = this.detailItem.memberList
      let newItem = {}
      newItem = {id: 0, first_name: "", last_name: "", email: "", telNo: ""}
      inputList.splice(id + 1, 0, newItem)
      this.detailItem.memberList = this.makeNewInput(inputList)
    },

    // 入力欄削除
    removeInput(id) {
      let inputList = this.detailItem.memberList
      inputList = inputList.filter((input) => {
        return input.id !== id
      })
      this.detailItem.memberList = this.makeNewInput(inputList)
    },

    // ID振り直し
    makeNewInput(inputList) {
      let newInputList = []
      for (let i = 0; i < inputList.length; i++) {
        newInputList.push({
          id: i,
          first_name: inputList[i].first_name,
          last_name: inputList[i].last_name,
          email: inputList[i].email,
          telNo: inputList[i].telNo
        })
      }

      return newInputList
    },
    addDetail() {
      this.errorMessage = ""
      this.isShow = true
      this.isShowAll = true
      this.formTittle = '登録'
      this.detailItem = {
        name: "",
        department: "",
        vendor: "",
        contract_type: "",
        status: "0",
        memo: "",
        memberList: [{id: 0, last_name: "", first_name: "", email: "", telNo: ""}],
      }

      this.$nextTick(() => {
        this.$refs.name.focus()
        this.$refs.selectableTable.clearSelected()
      })


    },
    register() {
      this.initDetailItem()
      let newItem = {}
      event.preventDefault()
      api.post('/api/customer/add', {
        name: this.detailItem.name,
        status: this.detailItem.status,
        contract_type: this.detailItem.contract_type,
        memo: this.detailItem.memo,
        vendor: this.detailItem.vendor,
        memberList: this.detailItem.memberList

      }).then(res => {

        //console.log(res.data)
        let status = res.data.statusCode
        let result = res.data.body.result

        if (status === 200 && result === 'success') {
          this.selectOwnerList = res.data.body.data.selectOwnerList
          newItem = res.data.body.data.detailItem
          this.$nextTick(() => {
            this.allItems.push({
              selected: true,
              id: newItem.id,
              name: newItem.name,
              department: newItem.department,
              memberList: newItem.memberList,
              vendor: newItem.vendor,
              contract_type: newItem.contract_type,
              status: newItem.status,
              memo: newItem.memo,
              owner: newItem.memberList[0].last_name + newItem.memberList[0].first_name,
              last_name: newItem.memberList[0].last_name,
              first_name: newItem.memberList[0].first_name,
              email: newItem.memberList[0].email,
              telNo: newItem.memberList[0].telNo,
              update_at: newItem.update_at,
            })

            this.errorMessage = "正常に登録しました。"
            this.messageColor = "success"
            this.isShow = true
            this.isShowAll = true

          })
        }
        if (status === 200 && result === 'failure') {
          this.errorMessage = res.data.body.error
          this.isShow = true
          this.isShowAll = true
          this.messageColor = "warning"
        }
      })
          .catch(error => {
            console.log(error)
            this.errorMessage = error
            this.messageColor = "danger"
            this.isShow = false
            this.isShowAll = false

          })
    },

    userUpdate() {
      let newItem = {}
      this.initDetailItem()
      const id = this.selected.id
      event.preventDefault()
      api.put('/api/customer/update/' + id, {
        newName: this.newName,
        name: this.detailItem.name,
        contract_type: this.detailItem.contract_type,
        vendor: this.detailItem.vendor,
        memberList: this.detailItem.memberList,
        status: this.detailItem.status,
        memo: this.detailItem.memo,
      }).then(res => {

        //console.log(res.data)
        let status = res.data.statusCode
        let result = res.data.body.result

        if (status === 200 && result === 'success') {
          newItem = res.data.body.data.detailItem
          this.$nextTick(() => {
            const memberList = newItem.memberList.find(v => v.id === 0)
            const index = this.allItems.findIndex(v => v.id === newItem.id)
            this.allItems[index]['name'] = newItem.name
            this.allItems[index]['vendor'] = newItem.vendor
            this.allItems[index]['status'] = newItem.status
            this.allItems[index]['contract_type'] = newItem.contract_type
            this.allItems[index]['memo'] = newItem.memo
            this.allItems[index]['memberList'] = newItem.memberList
            this.allItems[index]['owner'] = memberList.last_name + memberList.first_name
            this.allItems[index]['last_name'] = memberList.last_name
            this.allItems[index]['first_name'] = memberList.first_name
            this.allItems[index]['email'] = memberList.email
            this.allItems[index]['telNo'] = memberList.telNo
            this.allItems[index]['update_at'] = newItem.update_at
            this.newName = newItem.name
            this.isShow = true
            this.isShowAll = true
            this.errorMessage = "正常に更新しました。"
            this.messageColor = "success"

          })
        }
        if (status === 200 && result === 'failure') {
          this.errorMessage = res.data.body.error
          this.isShow = true
          this.isShowAll = true
          this.messageColor = "warning"
        }
      })
          .catch(error => {
            console.log(error)
            this.errorMessage = error
            this.messageColor = "danger"
            this.isShow = false
            this.isShowAll = false
          })
    },

    onSubmit() {
      if (this.$refs.addForm.validate()) {
        if (this.formTittle === "登録") {
          this.register()
        } else {
          this.userUpdate()
        }
      }

    },
    userDelete() {
      let items = []
      let deleteId = this.selected.id

      this.$confirm("「確定」をクリックすると、削除したレコードを元に戻すことはできません。これのレコードを削除してもよろしいですか?", "").then(() => {

        api.delete('/api/customer/delete/' + deleteId, {}).then(res => {

          //console.log(res.data)
          let status = res.data.statusCode
          let result = res.data.body.result

          if (status === 200 && result === 'success') {

            items = this.allItems.filter((item) => {
              return item.id !== deleteId
            })
            this.allItems = items
            this.totalRows = this.allItems.length
            this.isShow = false
            this.isShowAll = true
            this.errorMessage = "正常に削除しました。"
            this.messageColor = "success"


          }
          if (status === 200 && result === 'failure') {
            this.errorMessage = res.data.body.error
            this.messageColor = "warning"
            this.isShow = false
            this.isShowAll = true

          }
        })
            .catch(error => {
              console.log(error)
              this.errorMessage = error
              this.messageColor = "danger"
              this.isShow = false
              this.isShowAll = true
            })

      })

    },

  },
}
</script>

<style lang="scss" scoped src="./CustomerRegister.scss"/>
