import Vue from 'vue'
import {BootstrapVue, DropdownPlugin, IconsPlugin, PaginationPlugin, TablePlugin} from 'bootstrap-vue'
import App from './App.vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import ElementUI from 'element-ui'
import tinymce from 'vue-tinymce-editor'
import VueEasyTable from "vue-easytable";
import 'vue-easytable/libs/theme-default/index.css'

import 'element-ui/lib/theme-chalk/index.css'
import router from './Routes'
import store from './store'

// import default theme
import "vue-easytable/libs/theme-default/index.css";
// for online edit
import "vue-easytable/libs/font/iconfont.css";
import VLink from 'vue-v-link'
import vuetify from "./plugins/vuetify";
import JsonExcel from 'vue-json-excel'

Vue.use(BootstrapVue)
Vue.use(PaginationPlugin)
Vue.use(VLink)
Vue.use(IconsPlugin)
Vue.use(TablePlugin)
Vue.use(DropdownPlugin)
Vue.use(ElementUI)
Vue.component('tinymce', tinymce)
Vue.component('downloadExcel', JsonExcel)
Vue.use(VueEasyTable)
Vue.config.productionTip = false
Vue.config.silent = true
Vue.config.dectools = false

new Vue({
    store,
    router,
    vuetify,
    render: h => h(App),
}).$mount('#app')
