import api from '@/api'
import {EventBus} from '@/utils'

export default {
  namespaced: true,
  state: {
    loggedIn: false
  },

  mutations: {
    login (state) {
      state.loggedIn = true
      console.log(state.loggedIn)
    },
    logout (state) {
      state.loggedIn = false
      console.log(state.loggedIn)
    }
  },
  actions: {
    login ( { commit }, payload)
    {
      // eslint-disable-next-line no-irregular-whitespace
      EventBus.$emit('login', 'ログインしています')

      api.post('/api/login', {
        username: payload.username,
        password: payload.password,
        csrf_token: payload.csrf_token
      }).then(function (response) {
        commit('login')
        // eslint-disable-next-line no-unused-vars
        let json_data = JSON.stringify(response.data)
        let username = JSON.parse(json_data)['username']
        let access_token = JSON.parse(json_data)['access_token']
        // eslint-disable-next-line no-unused-vars
        let email = JSON.parse(json_data)['email']
        let active = JSON.parse(json_data)['active']
        let role = JSON.parse(json_data)['role']
        let count_new= JSON.parse(json_data)['count_new']
        let current_login_at = JSON.parse(json_data)['current_login_at']
        window.sessionStorage.setItem('user_id', email)
        window.sessionStorage.setItem('authenticated', true)

        window.sessionStorage.setItem('username', username)
        window.sessionStorage.setItem('active', active)
        window.sessionStorage.setItem('role', role)
        window.sessionStorage.setItem('sidebarStatic', true)
        window.sessionStorage.setItem('current_login_at', current_login_at)
        window.sessionStorage.setItem('access_token', access_token)
        window.sessionStorage.setItem('count_new', count_new)
        payload.router.push('/app/dashboard')
      }).catch(function (error) {
        if (error.response) {
          if (error.response.status === 401) {
            window.sessionStorage.clear()
            EventBus.$emit('failedAuthentication', JSON.parse(JSON.stringify(error.response.data))['message'])
            EventBus.$emit('login', 'ログイン')
          }
        } else {
          let message ="再度ログインしてください。"
          EventBus.$emit('failedAuthentication',message)
          EventBus.$emit('login', 'ログイン')
        }
      })
    },

    logout ({ commit }, payload) {
      payload.router.push('/login')
      api.post('/api/logout', {
        csrf_token: payload.csrf_token
        // eslint-disable-next-line no-unused-vars
      }).then(function (response) {
        commit('logout')
        window.sessionStorage.clear()
        // eslint-disable-next-line no-unused-vars
      }).catch(function (error) {
            window.sessionStorage.clear()
          }
      )
    }
  }
}
