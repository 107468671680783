
<template>
  <div class="tables-basic">
    <b-breadcrumb>
      <b-breadcrumb-item>IOA Exclusions</b-breadcrumb-item>
      <b-breadcrumb-item active>IOA Exclusions</b-breadcrumb-item>
    </b-breadcrumb>
    <b-row>
      <b-col>
        <Widget class="h-100 mb-0" title="">
          <div class="pb-xlg h-100">
            <b-row align-h="end" >
              <b-col>
                <div class="form-group">
                  <b-form-group
                      label-for="select"
                  >
                    <b-form-select v-model="customerSearchKey">
                      <option :value="null">-- CUSTOMER --</option>
                      <option
                          v-for="(option, idx) in customerOptions"
                          :key="idx"
                          :value="option"
                      >
                        {{ option }}
                      </option>
                    </b-form-select>
                  </b-form-group>
                </div>
              </b-col>
              <b-col>
                <div class="form-group">
                  <b-form-group
                      label-for="select"
                  >
                    <b-form-select v-model="nameSearchKey">
                      <option :value="null">-- NAME --</option>
                      <option
                          v-for="(option, idx) in nameOptions"
                          :key="idx"
                          :value="option"
                      >{{ option }}
                      </option>
                    </b-form-select>
                  </b-form-group>
                </div>
              </b-col>
              <b-col>
                <div class="form-group">
                  <b-form-group
                      label-for="select"
                  >
                    <b-form-select v-model="ioaSearchKey">
                      <option :value="null">-- IOA --</option>
                      <option
                          v-for="(option, idx) in ioaOptions"
                          :key="idx"
                          :value="option"
                      >{{ option }}
                      </option>
                    </b-form-select>
                  </b-form-group>
                </div>
              </b-col>
              <b-col>
                <div class="form-group">
                  <b-form-group
                      label-for="select"
                  >
                    <b-form-select v-model="descriptionSearchKey">
                      <option :value="null">-- DESCRIPTION --</option>
                      <option
                          v-for="(option, idx) in descriptionOptions"
                          :key="idx"
                          :value="option"
                      >{{ option }}
                      </option>
                    </b-form-select>
                  </b-form-group>
                </div>
              </b-col>
            <b-col >
              <div class="form-group">
                  <b-form-group
                  label=""
                  label-for="per-page-select"
                  label-align="left"
              >
                <b-form-select
                    id="per-page-select"
                    v-model="perPage"
                    :options="pageOptions"
                    @change="initDetailItem"

                ></b-form-select>
              </b-form-group></div>
            </b-col>
            </b-row>


              <b-row>
                <b-col>
                  <b-table
                      style="width:100%"
                      :items="allItems"
                      :fields="visibleFields"
                      :current-page="currentPage"
                      :per-page="perPage"
                      :sort-by.sync="sortBy"
                      :sort-desc.sync="sortDesc"
                      :filter="filter"
                      :filter-function="tableFilter"
                      show-empty
                      responsive="sm"
                      @filtered="onFiltered"
                      :select-mode="selectMode"
                      selectable
                      outlined
                      head-variant="light"
                      selected-variant="success"
                      @row-clicked="onRowSelected"
                      primary-key="url"
                  >
                  <template #cell(index)="row">
                      {{ row.index + 1 }}
                    </template>
                    <template #cell(selected)="{ rowSelected }">
                      <template v-if="rowSelected">
                        <span aria-hidden="true" style="color:#5c1515;">&check;</span>
                        <span class="sr-only">Selected</span>
                      </template>
                      <template v-else>
                        <span aria-hidden="true">&nbsp;</span>
                        <span class="sr-only">Not selected</span>
                      </template>
                    </template>
                  <template #cell(customer_name)="row">
                      <template v-if="row.value.length > 20">
                        <span v-b-popover.hover.focus="row.value">{{ row.value.substring(0, 20) }}...</span>
                      </template>
                      <template v-else>
                        <span>{{ row.value }}</span>
                      </template>
                    </template>

                    <template #cell(vendor)="row">
                      <template v-if="row.value.length > 10">
                        <span v-b-popover.hover.focus="row.value">{{ row.value.substring(0, 10) }}...</span>
                      </template>
                      <template v-else>
                        <span>{{ row.value }}</span>
                      </template>
                    </template>

                    <template #cell(name)="row">
                      <template v-if="row.value.length > 20">
                        <span v-b-popover.hover.focus="row.value">{{ row.value.substring(0, 20) }}...</span>
                      </template>
                      <template v-else>
                        <span>{{ row.value }}</span>
                      </template>
                    </template>
                   <template #cell(description)="row">
                      <template v-if="row.value.length > 20">
                        <span v-b-popover.hover.focus="row.value">{{ row.value.substring(0, 20) }}...</span>
                      </template>
                      <template v-else>
                        <span>{{ row.value }}</span>
                      </template>
                    </template>
                    <template #cell(ifn_regex)="row">
                      <template v-if="row.value.length > 20">
                        <span v-b-popover.hover.focus="row.value">{{ row.value.substring(0, 20) }}...</span>
                      </template>
                      <template v-else>
                        <span>{{ row.value }}</span>
                      </template>
                    </template>

                    <template #cell(last_modified)="row">
                      <template v-if="row.value.length > 10">
                        <span v-b-popover.hover.focus="row.value">{{ row.value.substring(0, 10) }}...</span>
                      </template>
                      <template v-else>
                        <span>{{ row.value }}</span>
                      </template>
                    </template>

                  </b-table>
                </b-col>
              </b-row>
              <b-row>
                <download-excel
                    :data="formattedDataCsv"
                    :fields="json_fields"
                    class="btn btn-default"
                    name="ioa_exclusion_list.csv"
                    type="csv"
                    worksheet="ioa_exclusion"
                >
                  <el-button plain type="primary">ダンロード<i class="el-icon-download el-icon--right"></i></el-button>
                </download-excel>
                <b-col></b-col>
                <b-col class="my-0">
                  <b-pagination
                      v-model="currentPage"
                      :per-page="perPage"
                      :total-rows="totalRows"
                      align="left"
                      class="my-0"
                      size="md"
                      @page-click="initDetailItem"
                  ></b-pagination>
                </b-col>
              </b-row>
            </div>
        </Widget>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Widget from '@/components/Widget/Widget'
import api from "@/api"

export default {
  name: 'IOAExclusions',
  components: { Widget },
  computed: {
    formattedDataCsv() {
      return this.allItems.map(item => {
        return {
          customer_name: item.customer_name,
          name: item.name,
          pattern_name: item.pattern_name,
          description: item.description ,
          ifn_regex: item.ifn_regex,
          modified_by: item.modified_by,
          last_modified: item.last_modified,
        }
      })
    },
    filter: function () {
      return [this.customerSearchKey, this.nameSearchKey, this.ioaSearchKey, this.descriptionSearchKey]

    },
    visibleFields() {
      return this.fields.filter(field => field.visible)
    }
  },
  data () {
    return {
      mailHref:'',
      mailMessage:'',
      mailStyle:'display: none',
      eMailTo:[],
      lIndex:0,
      messageColor:'',
      errorMessage:'',
      selectMode: 'single',//'multi'
      selected: {},
      statusColor: ['warning','info','success','primary','secondary'],
      aResultText: ['Low','High'],
      aResultColor: ['warning','danger'],
      sortBy: 'id',
      sortDesc: true,
      nameSearchKey:null,
      nameOptions:[],
      customerSearchKey: null,
      customerOptions: [],
      ioaSearchKey: null,
      ioaOptions: [],
      descriptionSearchKey: null,
      descriptionOptions: [],
      allItems: [],
      json_fields: {
        'Customer': 'customer_name',
        'Name': 'name',
        'IOA': 'pattern_name',
        'Description': 'description',
        'IFN　REGEXD': 'ifn_regex',
        'Modified_by': 'modified_by',
        'Modified_timestamp': 'last_modified'
      },
      fields: [
        { key: 'id', label:'No.',sortable: true ,class: 'text-center',visible: false,},//PK
        { key: 'customer_name', label:'Customer',sortable: true ,class: 'text-left',visible: true},
        { key: 'name', label:'Name',sortable: true ,class: 'text-left',visible: true},
        { key: 'pattern_name', label:'IOA',sortable: true ,class: 'text-left',visible: true},
        { key: 'description', label:'Description',sortable: true ,class: 'text-left',visible: true},
        { key: 'ifn_regex', label:'INF　Regex',sortable: true ,class: 'text-left',visible: true},
        { key: 'last_modified', label:'Last Modified',sortable: true ,class: 'text-left',visible: true},
      ],

      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 20, 30, {value: 100, text: "Show a lot"}],
      isShow:false,
      detailItem:{},
      selectOwnerList:[],
    }
  },
  mounted() {
    this.errorMessage=''
    this.allItems=[]
    this.isShow=false
    this.vendorOptions=[]
    this.nameOptions=[]
    this.ownerOptions=[]

    this.errorMessage=''
    this.totalRows = this.allItems.length

    api.post('/api/ioa_exclusions/list', {}).then (res => {

      let status = res.data.statusCode
      let result = res.data.body.result
      if (status === 200 && result === 'success') {
        this.allItems = res.data.body.data['allItems']
        this.customerOptions = res.data.body.data['customerOptions']
        this.nameOptions = res.data.body.data['nameOptions']
        this.ioaOptions = res.data.body.data['ioaOptions']
        this.descriptionOptions = res.data.body.data['descriptionOptions']
        this.totalRows = this.allItems.length
        console.log(res.data.body.data)

      }
      if (status === 200 && result === 'failure') {
        this.errorMessage = res.data.body.error
        this.messageColor = "warning"

      }
    })
        .catch(error => {
          console.log(error);
          this.errorMessage=error;
        })

  },

  methods: {

    initDetailItem(){
      this.detailItem ={}
      this.isShow = false
      this.errorMessage=""
      this.lIndex=0

    },


    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
      this.errorMessage=''
      this.initDetailItem()
      this.detailItem = {}

    },
    onRowSelected (selectedRows) {
      this.selected = selectedRows
      this.detailItem = selectedRows[0]
      this.isShow = true
      this.errorMessage=""
      this.lIndex=0
    },
    tableFilter(row) {
      let filter_customer_name = this.customerSearchKey ? String(row.customer_name).toLocaleLowerCase() === String(this.customerSearchKey).toLocaleLowerCase() : true
      let filter_description = this.descriptionSearchKey ? String(row.description).toLocaleLowerCase() === String(this.descriptionSearchKey).toLocaleLowerCase() : true
      let filter_ioa = this.ioaSearchKey ? String(row.pattern_name).toLocaleLowerCase() === String(this.ioaSearchKey).toLocaleLowerCase() : true
      let filter_name = this.nameSearchKey ? String(row.name).toLocaleLowerCase() === String(this.nameSearchKey).toLocaleLowerCase() : true
      return filter_customer_name && filter_name && filter_description && filter_ioa
    },
  },

}
</script>

<style src="./IoaExclusion.scss" lang="scss" scoped />