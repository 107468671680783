import axios from 'axios'
//import {Message} from "element-ui";
//import popup from "element-ui/src/utils/popup";
//import {EventBus} from "@/utils";
import router from "@/Routes";

const service = axios.create({
    // eslint-disable-next-line no-undef
    baseURL: process.env.API_BASE_URL,
    //baseURL: 'http://127.0.0.1:5006',
    //baseURL: 'https://artemis.secureedge.jp',
    //baseURL: 'http://18.176.248.169:5006',
    timeout: 100000,
    headers: {
        'Content-type': 'application/json'
    },
    withCredentials: true
})

axios.defaults.transformRequest = [function (data) {
  console.log('transformRequest', data)
  let newData = ''
  for (let k in data) {
    newData += encodeURIComponent(k) + '=' + encodeURIComponent(data[k]) + '&'
  }
  return newData
}]

service.interceptors.request.use(
  config => {
    // 发送请求之前，要做的业务
    if(sessionStorage.getItem("access_token"))
    {
      config.headers.Authorization=sessionStorage.getItem("access_token")
    }
    return config
  },
  error => {
    // 错误处理代码
    return Promise.reject(error)
  }
)

// response拦截器
service.interceptors.response.use(
  response => {
    // 数据响应之后，要做的业务
    return response
  },
  error => {
      if(error.response){
          console.log('axios:'+error.response.status);
          switch(error.response.status){
              case 403:
                  sessionStorage.clear()
                  router.replace({
                      path: '/login',
                      query: {redirect: router.currentRoute.fullPath}
                  })
                  break
              case 401:
                  sessionStorage.clear()
                  router.replace({
                      path: '/login',
                      query: {redirect: router.currentRoute.fullPath}
                  })
                  break
          }
      }
      return Promise.reject(error)
  }
)
export default service
