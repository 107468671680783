
<template>
  <div>
    <b-breadcrumb>
      <!--<b-breadcrumb-item>ツール</b-breadcrumb-item>-->
      <b-breadcrumb-item active></b-breadcrumb-item>
    </b-breadcrumb>
    <!--<h1 class="page-title">ダッシュボード</h1>-->

    <b-row>
      <b-col xs="12">
        <Widget
            title="<h5> <span class='fw-semi-bold'> </span></h5>"
            bodyClass="widget-table-overflow"
            customHeader
        >

          <div class="table-responsive">
            <form class="mt" @submit.prevent="onChange">
              <b-alert v-if="errorMessage!==''" :show="!!errorMessage" :variant="messageColor" class="m-8"><h4>{{ errorMessage }}</h4></b-alert>
            <table class="table table-sm mb-0">
              <tr class="text-muted">
                <th>ユーザ名</th>
                <td>{{ username }}</td>
              </tr>
              <tr class="text-muted">
                <th>旧パスワード</th>
                <td>
                  <input v-model="old_password"  class="form-control no-border" ref="old_password"
                         required type="password" name="password" placeholder="旧パスワード" autocomplete="old_password" />
                </td>
              </tr>
              <tr class="text-muted">
                <th>新パスワード</th>
                <td>
                  <div class="form-group">
                    <input v-model="password"  class="form-control no-border" ref="password"
                           required type="password" name="password" placeholder="新パスワード" autocomplete="password" />
                  </div>
                </td>
              </tr>
              <tr class="text-muted">
                <th>新パスワード</th>
                <td>
                  <div class="form-group">
                    <input v-model="password_confirm"  class="form-control no-border" ref="password_confirm"
                           required type="password" name="password_confirm" placeholder="新パスワード" autocomplete="password_confirm" />
                  </div>
                </td>
              </tr>
              <tr class="text-muted">
                <th></th>
                <td>
                <div class="center clearfix">
                  <b-button type="submit"  variant="success" class="p-1 px-3 btn-xs"  > 変  更</b-button>
                </div>
                </td>
                <td>
                </td>
              </tr>
            </table>

            </form>
          </div>
        </Widget>
      </b-col>
    </b-row>
  </div>
</template>

<script>

/* eslint-disable */
import 'imports-loader?jQuery=jquery,this=>window!flot';
import 'imports-loader?jQuery=jquery,this=>window!flot/jquery.flot.pie';
/* eslint-enable */
import Widget from '@/components/Widget/Widget'
import api from "@/api";


export default {
  name: 'ChangePassword',
  components: { Widget },
  data () {
    return {
      messageColor:'',
      errorMessage:'',
      token: new URL(location.href).searchParams.get('token'),
      old_password:'',
      password:'',
      password_confirm:'',
      username:''

    }
  },
  methods: {
    onChange: function () {
      api.post('/api/change_password', {
        email: window.sessionStorage.getItem("email"),
        old_password: this.old_password,
        password: this.password,
        password_confirm: this.password_confirm
      }).then (res => {

        let status =res.data.statusCode
        let result =res.data.body.result

        if (status===200 && result==='success'){

          this.errorMessage="パスワードを変更しました。"
          this.messageColor="success"

        }
        if (status===200 && result==='failure'){
          this.errorMessage=res.data.body.error
          this.messageColor="warning"
        }
      })
          .catch(error => {
            this.errorMessage=error
            this.messageColor="danger"
          })
    }
  },
  mounted() {
    // eslint-disable-next-line no-irregular-whitespace
    this.username = window.sessionStorage.getItem('username')

  },

}
</script>

<style src="./Profile.scss" lang="scss" scoped />

