<template>
  <div class="tables-basic">
    <b-breadcrumb>
      <b-breadcrumb-item>ユーザ管理</b-breadcrumb-item>
      <b-breadcrumb-item active>実績</b-breadcrumb-item>
    </b-breadcrumb>


    <b-row>
      <b-col>
        <Widget class="h-100 mb-0" title="">
          <div class="table-responsive">
            <el-table :data="allItems" class="able table-lg mb-0" stripe style="width: 100%">
              <el-table-column v-for="(item,index) in tableHeads"
                               :key="index"
                               :index="item.index"
                               :label="item.label"
                               :property="item.property"
                               align="center">
                <template slot-scope="scope">
                  <div>
                    <p v-if="scope.column.property != 'items'">{{ scope.row[scope.column.property] }}</p>
                    <p v-else>{{ scope.row[scope.column.property][scope.column.index].count }}</p>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </Widget>

      </b-col>
    </b-row>
  </div>
</template>

<script>

import Widget from '@/components/Widget/Widget'
import api from "@/api"

export default {
  name: 'Achievement',
  components: {Widget,},
  data: function () {
    return {
      allItems: [],
      tableHeads: [],
    }
  },
  created() {
    this.isShow = false
    api.post('/api/achievement/init', {}).then(res => {
      console.log(res.data)
      let status = res.data.statusCode
      let result = res.data.body.result

      if (status === 200 && result === 'success') {
        console.log("success")
        this.allItems = res.data.body.data.allItems
        this.totalRows = this.allItems.length
        console.log(this.allItems)
        const tableHead = [];
        let selectStatistics =  res.data.body.data.tables_headers;//this.allItems[0].items;
        selectStatistics.forEach((item, index) => {
          tableHead.push({index: index, property: 'items', label: item });
        });
        const anotherTableHead = [
          {
            label: '担当者',
            property: 'username'
          }
        ]
        this.tableHeads = [...anotherTableHead, ...tableHead];
        console.log(tableHead)
        console.log("test")
        console.log(this.tableHeads )
      }
      if (status === 200 && result === 'failure') {
        this.errorMessage = res.data.body.error
      }

      console.log("failure")
    })
        .catch(error => {
          console.log(error)
          this.errorMessage = error
        })

  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.allItems.length
  },
  methods: {

  },

}
</script>
<style lang="scss" scoped src="./Achievement.scss"/>
