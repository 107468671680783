<template>
  <div>
    <b-breadcrumb>
      <b-breadcrumb-item>ユーザ管理</b-breadcrumb-item>
      <b-breadcrumb-item active>ユーザ一覧</b-breadcrumb-item>
    </b-breadcrumb>
    <!--<h1 class="page-title">ダッシュボード</h1>-->
    <b-row>
      <b-col xs="12">
        <Widget
            bodyClass="widget-table-overflow"
            customHeader
            title="<h5> <span class='fw-semi-bold'> </span></h5>"
        >
          <div class="table-responsive">
            <b-table
                ref="selectableTable"
                :current-page="currentPage"
                :fields="visibleFields"
                :items="allItems"
                :per-page="perPage"
                :select-mode="selectMode"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                head-variant="light"
                outlined
                responsive="lg"
                selectable
                selected-variant="success"
                show-empty
                style="width:100%"
                @filtered="onFiltered"
                @row-clicked="onRowSelected"
            >

              <template #cell(selected)="{ rowSelected }">
                <template v-if="rowSelected">
                  <strong><span aria-hidden="true" style="color:#000000;"><h4>&check;</h4></span></strong>
                  <span class="sr-only">Selected</span>
                </template>
                <template v-else>
                  <span aria-hidden="true">&nbsp;</span>
                  <span class="sr-only">Not selected</span>
                </template>
              </template>
              <template #cell(index)="row">
                {{ row.index + 1 }}
              </template>
              <template #cell(username)="row">
                <template v-if="row.value.length > 20">
                  <span v-b-popover.hover.focus="row.value">{{ row.value.substring(0, 20) }}...</span>
                </template>
                <template v-else>
                  <span>{{ row.value }}</span>
                </template>
              </template>

              <template #cell(email)="row">
                <template v-if="row.value.length > 30">
                  <span v-b-popover.hover.focus="row.value">{{ row.value.substring(0, 30) }}...</span>
                </template>
                <template v-else>
                  <span>{{ row.value }}</span>
                </template>
              </template>

              <template #cell(role)="row">
                <span>{{ roleText[row.value] }}</span>
              </template>

              <template #cell(active)="row">
                <b-button :variant="statusColor[row.value]" class="p-1 px-3 btn-xs" style="width: 80px">
                  {{ statusText[row.value] }}
                </b-button>
              </template>

              <template #cell(delete)={rowSelected}>
                <template v-if="rowSelected && row.item.email !== loggedInEmail">
                  <strong><span aria-hidden="true" style="color:#ff0000;"><a
                      @click="dataDelete"><h4>&chi;</h4></a></span></strong>
                  <span class="sr-only">Selected</span>
                </template>
                <template v-else>
                  <span aria-hidden="true">&nbsp;</span>
                  <span class="sr-only">Not selected</span>
                </template>
              </template>
            </b-table>
          </div>
          <b-row>
            <b-col class="pb-2" lg="1"></b-col>
            <b-col class="pb-2" lg="4">
              <b-btn @click="addDetail">
                <b-icon icon="person-plus-fill"></b-icon>
              </b-btn>
            </b-col>

            <b-col class="pb-2" lg="3">
              <b-pagination
                  v-model="currentPage"
                  :per-page="perPage"
                  :total-rows="totalRows"
                  align="left"
                  class="my-0"
                  size="md"
                  @page-click="initDetailItem"
              ></b-pagination>
            </b-col>
            <b-col class="pb-2" lg="4">
              <div class="left">
                <b-form-group
                    label=""
                    label-align="left"
                    label-for="per-page-select"
                    style="margin-left:70%;width:30%"
                >
                  <b-form-select
                      id="per-page-select"
                      v-model="perPage"
                      :options="pageOptions"
                      @change="initDetailItem"
                  ></b-form-select>
                </b-form-group>
              </div>
            </b-col>
          </b-row>

        </Widget>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <div v-if="isShowAll">
          <Widget class="mx-auto" customHeader name="select" title="<h3 class='mt-0 center'></h3>">
            <b-alert v-if="message!==''" :variant="messageColor" class="m-8" show><h4>{{ message }}</h4></b-alert>
            <form @submit.prevent="onSubmit">
              <table v-if="isShow" class="table">
                <tr>
                  <th class="title-class-name">氏名</th>
                  <td class="data-cell-class-name1">
                    <input ref="last_name" v-model="detailItem.last_name" autocomplete="last_name"
                           class="form-control no-border" name="last_name" placeholder="氏名" required type="text"/></td>
                </tr>
                <tr>
                  <th class="title-class-name">名前</th>
                  <td class="data-cell-class-name1">
                    <input ref="username" v-model="detailItem.first_name" autocomplete="first_name"
                           class="form-control no-border" name="first_name" placeholder="名前" required type="text"/></td>
                </tr>
                <tr>
                  <td class="title-class-name">メールアドレス</td>
                  <td class="data-cell-class-name1">
                    <input ref="email" v-model="detailItem.email" autocomplete="email"
                           class="form-control no-border" name="email" placeholder="メールアドレス" required type="email"/>
                  </td>
                </tr>
                <tr>
                  <td class="title-class-name">ロール</td>
                  <td class="data-cell-class-name1">
                    <b-form-radio-group
                        v-model="detailItem.role"
                        :options="radioRole"
                        class="mb-3"
                        disabled-field="notEnabled"
                        required
                        text-field="name"
                        value-field="item"
                    ></b-form-radio-group>

                  </td>
                </tr>
                <tr>
                  <td class="title-class-name">ステータス</td>
                  <td class="data-cell-class-name1">
                    <b-form-radio-group
                        v-model="detailItem.active"
                        :options="radioStatus"
                        class="mb-3"
                        disabled-field="notEnabled"
                        required
                        text-field="name"
                        value-field="item"
                    ></b-form-radio-group>
                  </td>
                </tr>
                <tr class="text-muted">
                  <th></th>
                  <td>
                    <div class="center clearfix">
                      <b-button class="btn" type="submit" variant="success"> {{ formTittle }}</b-button>

                    </div>
                  </td>
                  <td>
                  </td>
                </tr>
              </table>
            </form>
          </Widget>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Widget from '@/components/Widget/Widget'
import api from "@/api"

export default {
  name: "UserManage",
  components: {Widget},
  computed: {
    visibleFields() {
      return this.fields.filter(field => field.visible)
    },
    loggedInEmail() {
      return window.localStorage.getItem('email')
    }

  },
  data() {
    return {
      lIndex: 0,
      roleText: ['担当者', '管理者'],
      statusColor: ['secondary', 'warning'],
      statusText: ['無効', '有効'],
      selectMode: 'single',
      selected: {},
      sortBy: 'id',
      sortDesc: true,
      allItems: [],
      fields: [
        {key: 'selected', label: '', visible: true, thStyle: {width: "0px"},},
        {key: 'index', label: '#', visible: false},//TODO TEST
        {key: 'id', label: '#', sortable: true, class: 'text-center', visible: false, thStyle: {width: "0px"},},//PK
        {key: 'username', label: 'ユーザ名', sortable: true, class: 'text-center', visible: true},
        {key: 'first_name', label: '名前', sortable: false, class: 'text-center', visible: false},
        {key: 'last_name', label: '氏名', sortable: false, class: 'text-center', visible: false},
        {key: 'email', label: 'メールアドレス', sortable: true, class: 'text-center', visible: true},
        {key: 'role', label: 'ロール', sortable: true, class: 'text-center', visible: true},
        {key: 'update_at', label: '更新日時', sortable: true, class: 'text-center', visible: true},
        {key: 'active', label: 'ステータス', sortable: true, class: 'text-center', visible: true},
        {key: 'delete', label: '', visible: true, thStyle: {width: "0px"},},
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 20, 30, {value: 100, text: "Show a lot"}],
      formTittle: '',
      detailItem: {},
      oldEmail: '',
      isShow: false,
      isShowAll: false,
      message: "",
      messageColor: "",
      radioStatus: [
        {item: '0', name: '無効'},
        {item: '1', name: '有効'},
      ],
      radioRole: [
        {item: '0', name: '担当者'},
        {item: '1', name: '管理者'},
      ],

    }
  },
  mounted() {
    // Set the initial number of items
    this.message = ''
    this.allItems = []
    this.isShow = false
    this.isShowAll = false

    api.post('/api/manage/list', {}).then(res => {
      //console.log(res.data)
      let status = res.data.statusCode
      let result = res.data.body.result

      if (status === 200 && result === 'success') {
        this.allItems = res.data.body.data['allItems']
        this.totalRows = this.allItems.length

      }
      if (status === 200 && result === 'failure') {
        this.message = res.data.body.error
        this.messageColor = "warning"

      }
    })
        .catch(error => {
          console.log(error)
          this.message = error
          this.messageColor = "danger"
          this.isShow = false
        })
  },

  methods: {

    initDetailItem() {
      this.message = ""
      this.lIndex = 0
    },

    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
      this.message = ''
      this.initDetailItem()
      this.$nextTick(() => {
        this.isShow = false
        this.detailItem = {}
      })
    },

    onRowSelected(item, index) {
      let oldItem = {}
      this.selected = item
      this.initDetailItem()
      this.formTittle = '更新'
      oldItem = this.allItems.find(v => v.id === item.id)
      this.detailItem = {
        username: oldItem.username,
        first_name: oldItem.first_name,
        last_name: oldItem.last_name,
        email: oldItem.email,
        active: oldItem.active,
        role: oldItem.role,
      }
      this.isShow = true
      this.isShowAll = true
      this.lIndex = index
      this.$nextTick(() => this.$refs.username.focus())
    },

    addDetail() {
      this.message = ""
      this.isShow = true
      this.isShowAll = true
      this.formTittle = '登録'
      this.detailItem = {
        username: "",
        first_name:"",
        last_name:'',
        email: "",
        active: "1",
        role: "0",
      }
      this.$nextTick(() => {
        this.$refs.first_name.focus()
        this.$refs.selectableTable.clearSelected()
      })
    },
    register() {
      this.initDetailItem()
      let newItem = {}
      event.preventDefault()
      api.post('/api/manage/add', {
        username: this.detailItem.last_name + this.detailItem.first_name,
        first_name: this.detailItem.first_name,
        last_name: this.detailItem.last_name,
        email: this.detailItem.email,
        role: this.detailItem.role,
        active: this.detailItem.active
      }).then(res => {
        let status = res.data.statusCode
        let result = res.data.body.result
        if (status === 200 && result === 'success') {
          this.$nextTick(() => {
            newItem = res.data.body.data.detailItem
            this.allItems.push(newItem)
            this.totalRows = this.allItems.length
            this.message = "正常に登録しました。"
            this.messageColor = "success"
            this.isShow = true
            this.isShowAll = true
          })
        }
        if (status === 200 && result === 'failure') {
          this.message = res.data.body.error
          this.isShow = true
          this.isShowAll = true
          this.messageColor = "warning"
        }
      })
          .catch(error => {
            console.log(error)
            this.message = error
            this.messageColor = "danger"
            this.isShow = false
            this.isShowAll = false
          })
    },

    aipUpdate() {
      let newItem = {}
      this.initDetailItem()
      const id = this.selected.id

      event.preventDefault()
      api.put('/api/manage/update/' + id, {
        email: this.detailItem.email,
        first_name: this.detailItem.first_name,
        last_name: this.detailItem.last_name,
        username: this.detailItem.last_name + this.detailItem.first_name,
        oldEmail: this.oldEmail,
        active: this.detailItem.active,
        role: this.detailItem.role,

      }).then(res => {

        let status = res.data.statusCode
        let result = res.data.body.result

        if (status === 200 && result === 'success') {

          this.$nextTick(() => {
            newItem = res.data.body.data.detailItem
            const index = this.allItems.findIndex(v => v.id === newItem.id)
            this.allItems[index]['username'] =newItem.last_name+newItem.first_name
            this.allItems[index]['first_name'] = newItem.first_name
            this.allItems[index]['last_name'] = newItem.last_name
            this.allItems[index]['email'] = newItem.email
            this.allItems[index]['active'] = newItem.active
            this.allItems[index]['role'] = newItem.role
            this.allItems[index]['update_at'] = newItem.update_at
            this.oldEmail = newItem.email
            this.isShow = true
            this.isShowAll = true
            this.message = "正常に更新しました。"
            this.messageColor = "success"

          })
        }
        if (status === 200 && result === 'failure') {
          this.message = res.data.body.error
          this.isShow = true
          this.isShowAll = true
          this.messageColor = "warning"
        }
      })
          .catch(error => {
            console.log(error)
            this.message = error
            this.messageColor = "danger"
            this.isShow = false
            this.isShowAll = false
          })
    },

    onSubmit() {
      //if (this.$refs.addForm.validate()) {
      if (this.formTittle === "登録") {
        this.register()
      } else {
        this.aipUpdate()
      }
      //}
    },

    dataDelete() {
      let items = []
      let deleteId = this.selected.id
      this.$confirm("「確定」をクリックすると、削除したレコードを元に戻すことはできません。これのレコードを削除してもよろしいですか?", "").then(() => {
        api.delete('/api/manage/delete/' + deleteId, {}).then(res => {
          let status = res.data.statusCode
          let result = res.data.body.result

          if (status === 200 && result === 'success') {
            items = this.allItems.filter((item) => {
              return item.id !== deleteId
            })
            this.allItems = items
            this.totalRows = this.allItems.length
            this.isShow = false
            this.isShowAll = true
            this.message = "正常に削除しました。"
            this.messageColor = "success"
          }
          if (status === 200 && result === 'failure') {
            this.message = res.data.body.error
            this.messageColor = "warning"
            this.isShow = false
            this.isShowAll = true
          }
        })
            .catch(error => {
              console.log(error)
              this.message = error
              this.messageColor = "danger"
              this.isShow = false
              this.isShowAll = true
            })
      })
    },
  }
}
</script>
<style lang="scss" scoped src="./UserManage.scss"/>