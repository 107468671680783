import Vue from 'vue';
import Router from 'vue-router';

import Layout from '@/components/Layout/Layout';
import Login from '@/pages/Login/Login';
import ErrorPage from '@/pages/Error/Error';
// Template
import EmailTemplate from '@/pages/Alerts/EmailTemplate';

// Tables
import AlertPage from '@/pages/Alerts/Alerts';

import AlertsSophosPage from '@/pages/Alerts/AlertsSophos';

// Main
import DashboardPage from '@/pages/Dashboard/Dashboard';

// add model of auth
import Auth from '@/store/modules/auth'

import ApiRegisterPage from "@/pages/ApiRegister/ApiRegister";

import ChangePasswordPage from "@/pages/Profile/ChangePassword";

import PasswordReset from "@/pages/Login/PasswordReset";
import PasswordForget from "@/pages/Login/PasswordForget";

import CustomerRegister from "@/pages/CustomerRegister/CustomerRegister";

import UserManagePage from "@/pages/Admin/UserManage";
import AchievementPage from "@/pages/Admin/Achievement";

import IoaExclusionPage from "@/pages/Exclusions/IoaExclusion"
import SVExclusionsPage from "@/pages/Exclusions/SensorVisibilityExclusion"
import MlExclusionsPage from "@/pages/Exclusions/MlExclusion"
//import Auth from "@/store/modules/auth";

Vue.use(Router);

let router = new Router({
    mode: 'history',
    routes: [
        {
            path: '/',
            name: 'index',
            redirect: '/app/dashboard',
        },
        {
            path: '/login',
            name: 'Login',
            component: Login,
        },
        {
            path: '/error',
            name: 'Error',
            component: ErrorPage,
        },
        {
            path: '/password-reset',
            name: 'PasswordReset',
            component: PasswordReset,
            props: true
        },
        {
            path: '/password-forget',
            name: 'PasswordForget',
            component: PasswordForget,
        },
        {
            path: '/app',
            name: 'Layout',
            component: Layout,
            meta: {
                requiresAuth: true
            },
            children: [
                {
                    path: 'dashboard',
                    name: 'DashboardPage',
                    component: DashboardPage,
                },
                {
                    path: 'alert/alerts',
                    name: "alertPage",
                    component: AlertPage,
                },
                {
                    path: 'alert/alert-sophos',
                    name: "alertSophosPage",
                    component: AlertsSophosPage,

                },
                {
                    path: 'exclusions/ioa-exclusion',
                    name: "IoaExclusionPage",
                    component: IoaExclusionPage,
                },
                {
                    path: 'exclusions/sensor-visibility',
                    name: "SVExclusionsPage",
                    component: SVExclusionsPage,
                },
                {
                    path: 'exclusions/machine-learning',
                    name: "MlExclusionsPage",
                    component: MlExclusionsPage,
                },
                {
                    path: 'alert/email-template',
                    name: "EmailTemplate",
                    component: EmailTemplate,
                },
                {
                    path: 'integration/api',
                    name: 'ApiRegisterPage',
                    component: ApiRegisterPage,
                },
                {
                    path: 'integration/customer',
                    name: 'CustomerRegister',
                    component: CustomerRegister,
                },
                {
                    path: 'admin/user-manage',
                    name: 'UserManage',
                    component: UserManagePage,
                },
                {
                    path: 'admin/achievement',
                    name: 'Achievement',
                    component: AchievementPage,
                },
                {
                    path: 'profile/change-password',
                    name: 'ChangePasswordPage',
                    component: ChangePasswordPage,
                },

            ],
        },
    ],
})


router.beforeEach((to, from, next) => {
    const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
    const isAuthenticated = Auth.state.loggedIn;
    if (requiresAuth && !isAuthenticated) {
        next('/login');
    } else {
        next();
    }
});

export default router