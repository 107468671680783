<template>
  <li v-if="!childrenLinks && isHeader" :class="{headerLink: true, className}">
    <router-link :to="link">
      <span class="icon">
        <i :class="fullIconName"></i>
      </span>
      {{ header }}  <sup v-if="label" class="headerLabel">{{ label }}</sup>
      <b-badge v-if="badge" class="badge rounded-f" pill variant="warning">{{ badge }}</b-badge>
    </router-link>
  </li>
  <li v-else-if="childrenLinks" :class="{headerLink: true, className}">
    <div @click="() => togglePanelCollapse(link)">
      <router-link :to="link" class="d-flex" event="">
        <span class="icon">
          <i :class="fullIconName"></i>
        </span>
        {{ header }}
        <sup v-if="label" class="headerLabel">{{ label }}</sup>
        <div :class="{caretWrapper: true, caretActive: isActive}">
          <i class="fa fa-angle-left"/>
        </div>
      </router-link>
    </div>
    <b-collapse :id="'collapse' + index" :visible="isActive">
      <ul>
        <NavLink v-for="link in childrenLinks"
                 :key="link.link"
                 :activeItem="activeItem"
                 :childrenLinks="link.childrenLinks"
                 :header="link.header"
                 :index="link.index"
                 :link="link.link"
                 :isSpecial="link.isSpecial"
                 :special="link.special"
        />
      </ul>
    </b-collapse>
  </li>
  <li v-else>
    <router-link :to="index !== 'menu' && link">
      {{ header }}  <sup v-if="isSpecial" class="headerLabel">  New({{special}}) </sup> <sup v-if="label" class="headerLabel">{{ label }}</sup>
    </router-link>
  </li>
</template>

<script>
import {mapActions} from 'vuex';

export default {
  name: 'NavLink',
  props: {
    badge: {type: String, dafault: ''},
    header: {type: String, default: ''},
    iconName: {type: String, default: ''},
    headerLink: {type: String, default: ''},
    link: {type: String, default: ''},
    childrenLinks: {type: Array, default: null},
    className: {type: String, default: ''},
    isHeader: {type: Boolean, default: false},
    deep: {type: Number, default: 0},
    activeItem: {type: String, default: ''},
    label: {type: String},
    index: {type: String},
    isSpecial: {type: Boolean, default: false},
    special: {type: String, default: ''},
  },
  data() {
    return {
      headerLinkWasClicked: true,
      new_count: '',
    };
  },
  methods: {
    ...mapActions('layout', ['changeSidebarActive']),
    togglePanelCollapse(link) {
      this.changeSidebarActive(link);
      this.headerLinkWasClicked = !this.headerLinkWasClicked
          || !this.activeItem.includes(this.index);
    },
  },
  computed: {
    fullIconName() {
      return `fi ${this.iconName}`;
    },
    isActive() {
      return (this.activeItem
          && this.activeItem.includes(this.index)
          && this.headerLinkWasClicked);
    },
  },
  mounted() {
    this.count_new = window.sessionStorage.getItem('count_new');
  },
  created() {
    this.count_new = window.sessionStorage.getItem('count_new');
  }
};
</script>

<style lang="scss" scoped src="./NavLink.scss"/>
