import Vue from 'vue';
import Vuex from 'vuex';
import layout from './layout';
import auth from './modules/auth'

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    layout,
    auth,
  },
  actions: {
    // eslint-disable-next-line no-unused-vars
  }
});
