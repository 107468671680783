<template>
  <nav
      :class="{sidebar: true, sidebarStatic, sidebarOpened}"
      @mouseenter="sidebarMouseEnter"
      @mouseleave="sidebarMouseLeave"
  >
    <header class="logo">
      <router-link to="/app/dashboard"><strong><span class="primary-word">SOC</span><span class="secondary-word"> Analyst</span></strong></router-link>

    </header>

    <div class="nav">
      <ul>
        <NavLink
            header="ダッシュボード"
            iconName="flaticon-home"
            index="dashboard"
            isHeader
            link="/app/dashboard"
        />

        <NavLink
            :activeItem="activeItem"
            :childrenLinks="[{ header: 'アラート一覧(CrowdStrike)',
                               isSpecial: true,
                               link: '/app/alert/alerts',
                               special: this.count_new,
                               },
                               { header: 'アラート一覧(Sophos)',
                               isSpecial: false,
                               link: '/app/alert/alert-sophos'
                               },
                               { header: 'メールテンプレート', link: '/app/alert/email-template' },
                            ]"
            header="アラート情報"
            iconName="flaticon-alarm"
            index="components"
            link="/app/components"
        />

        <NavLink
            :activeItem="activeItem"
            :childrenLinks="[
                            { header: 'IOA Exclusions', link: '/app/exclusions/ioa-exclusion' },
                            { header: 'Sensor-Visibility Exclusions', link: '/app/exclusions/sensor-visibility' },
                            { header: 'Machine-Learning Exclusions', link: '/app/exclusions/machine-learning' },
                            ]"
            header="除外リスト"
            iconName="flaticon-skip"
            index="components"
            link="/app/components"
        />
        <NavLink
                 :activeItem="activeItem"
                 :childrenLinks="[
          { header: '顧客登録', link: '/app/integration/customer' }   ,
          { header: 'API登録', link: '/app/integration/api' }   ]"
                 header="API連携"
                 iconName="flaticon-network"
                 index="components"
                 link="/app/components"
        />
        <NavLink v-if="role==='1'"
                 :activeItem="activeItem"
                 :childrenLinks="[
          { header: 'ユーザ一覧', link: '/app/admin/user-manage' },
          { header: '実績', link: '/app/admin/achievement' }
            ]"
                 header="ユーザ管理"
                 iconName="flaticon-user"
                 index="components"
                 link="/app/components"
        />

        <NavLink
            :activeItem="activeItem"
            :childrenLinks="[
          { header: 'パスワード変更', link: '/app/profile/change-password' },

        ]"
            header="プロファイル"
            iconName="flaticon-settings"
            index="components"
            link="/app/components"
        />

      </ul>

    </div>
  </nav>
</template>

<script>
import {mapActions, mapState} from 'vuex'
import isScreen from '@/core/screenHelper'
import NavLink from './NavLink/NavLink'

export default {
  name: 'Sidebar',
  components: {NavLink},

  mounted() {
    this.count_new = window.sessionStorage.getItem('count_new');
    this.username = window.sessionStorage.getItem('username');
    this.role = window.sessionStorage.getItem('role');
  },
  methods: {
    ...mapActions('layout', ['changeSidebarActive', 'switchSidebar']),
    setActiveByRoute() {
      const paths = this.$route.fullPath.split('/');
      paths.pop();
      this.changeSidebarActive(paths.join('/'));
    },
    sidebarMouseEnter() {
      if (!this.sidebarStatic && (isScreen('lg') || isScreen('xl'))) {
        this.switchSidebar(false);
        this.setActiveByRoute();
      }
    },
    sidebarMouseLeave() {
      if (!this.sidebarStatic && (isScreen('lg') || isScreen('xl'))) {
        this.switchSidebar(true);
        this.changeSidebarActive(null);
      }
    },
  },
  created() {
    this.setActiveByRoute();
    this.role = window.sessionStorage.getItem('role');
  },
  computed: {
    ...mapState('layout', {
      sidebarStatic: state => state.sidebarStatic,
      sidebarOpened: state => !state.sidebarClose,
      activeItem: state => state.sidebarActiveElement,
    }),
  },
};
</script>

<!-- Sidebar styles should be scoped -->
<style lang="scss" scoped src="./Sidebar.scss"/>