<template>
  <div class="login-page">
    <b-container>
      <h1 class="logo">
                <span class="avatar thumb  mr-2">
                    <img class="rounded-right " src="../../assets/logo/logo_corp.svg" alt="..." />
                </span>
        <span style="color:#01ab86" >セキュアエッジ </span>
      </h1>
      <Widget name="login" class="mx-auto" title="<h3 class='mt-0 center'></h3>" customHeader>
        <div class="clearfix">
          <div class="clearfix">
            <h4 class="center">SOC管理サイトへようこそ</h4>
          </div>
          <p></p>
          <br>
          <p class="same-account-explanation"></p>
        </div>
        <form class="mt" @submit.prevent="onLogin">
          <b-alert class="alert-sm" variant="danger" :show="!!errorMsg">
            {{errorMsg}}
          </b-alert>
          <div class="form-group">
            <input v-model="username"  class="form-control border-info" ref="username"
                   required type="text" name="username" placeholder="メールアドレス" autocomplete="username" />
          </div>
          <div class="form-group">
            <input v-model="password"  class="form-control border-info" ref="password"
                   required type="password" name="password" placeholder="パスワード" autocomplete="password" />
          </div>
          <div class="clearfix">
            <p class="text-xs text-right text-gray-500 fs-sm">
              <router-link active-class="btn-link right" to="/password-forget">パスワードをお忘れの場合</router-link>
            </p>
          </div>

          <div class="clearfix center">
            <b-button type="submit" size="lg"  class="form-control border-info" variant="success" > {{loginMsg}}</b-button>
          </div>

        </form>
      </Widget>

    </b-container>
    <footer class="footer">
    </footer>
  </div>
</template>

<script>
import {mapActions} from 'vuex'
import {EventBus} from '@/utils'
import Widget from '@/components/Widget/Widget';


export default {
  name: 'Login',
  components: {Widget},
  data() {
    return {
      username: '',
      password: '',
      errorMsg: '',
      loginMsg: 'ログイン'
    }
  },

  methods: {
    ...mapActions('auth', [
          'login'
        ]
    ),


    onLogin: function () {
      let self = this
      self.login({
        username: this.$data.username,
        password: this.$data.password,
        router: self.$router
      })
    },
  },

  mounted () {
    EventBus.$on('failedAuthentication', (msg) => {
      this.errorMsg = msg
    })
    EventBus.$on('login', (msg) => {
      this.loginMsg = msg
    })
  },

  beforeDestroy () {
    EventBus.$off('failedAuthentication')
  }
}
</script>

<style src="./Login.scss" lang="scss" scoped />
