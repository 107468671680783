<template>
  <div class="tables-basic">
    <b-breadcrumb>
      <b-breadcrumb-item>アラート情報(Sophos)</b-breadcrumb-item>
      <b-breadcrumb-item active>アラート一覧画面(Sophos)</b-breadcrumb-item>
    </b-breadcrumb>
    <b-row>
      <b-col>
        <Widget class="h-100 mb-0" title="">
          <div class="pb-xlg h-100">
            <b-form class="mt" @submit.prevent="onSearch">
              <b-row align-h="end">
                <b-col >
                  <b-form-datepicker v-model="startDate" :max="endDate" placeholder="-- 検知開始日 --" :show-weekdays="false" required></b-form-datepicker>
                </b-col>
                <b-col >
                  <b-form-datepicker v-model="endDate" :min="startDate" placeholder="-- 検知終了日 --" :show-weekdays="false" required></b-form-datepicker>
                </b-col>
                <b-col >
                  <b-button type="submit" variant="primary" > 検索<i class=" el-icon-search el-icon--right" ></i></b-button>
                </b-col>
              </b-row>
            </b-form>
            <b-row align-h="end">
              <!-- <b-col>
                <div class="form-group">
                  <b-form-group label-for="select">
                    <b-form-select v-model="vendorSearchKeys" multiple :select-size="3" >
                      <option value="null">-- ベンダー --</option>
                      <option
                          v-for="(option, idx) in vendorOptions"
                          :key="idx"
                          :value="option.text"
                      >{{ option.text }}
                      </option>
                    </b-form-select>
                  </b-form-group>
                </div>
              </b-col>
              -->
              <b-col>
                <div class="form-group">
                  <b-form-group
                      label-for="select"
                  >
                    <b-form-select v-model="nameSearchKeys" multiple :select-size="3" >
                      <option value="null">-- 会社名 --</option>
                      <option
                          v-for="(option, idx) in customerNameOptions"
                          :key="idx"
                          :title="option.customer_name"
                          :value="option.customer_name"
                      >
                        {{ option.customer_name }}
                      </option>
                    </b-form-select>
                  </b-form-group>
                </div>
              </b-col>
              <b-col>
                <div class="form-group">
                  <b-form-group
                      label-for="select"
                  >
                    <b-form-select v-model="severitySearchKeys" multiple :select-size="3" >
                      <option value="null">-- 重要性 --</option>
                      <option
                          v-for="(option, idx) in severityOptions"
                          :key="idx"
                          :value="option.text"
                      >
                        {{ option.text }}
                      </option>
                    </b-form-select>
                  </b-form-group>
                </div>
              </b-col>

              <b-col>
                <div class="form-group">
                  <b-form-group
                      label-for="select"
                  >
                    <b-form-select v-model="socUserNameSearchKeys" multiple :select-size="3" >
                      <option value="null">-- 対応者 --</option>
                      <option
                          v-for="(option, idx) in allSocUserOptions"
                          :key="idx"
                          :title="option.username"
                          :value="option.username"
                      >{{ option.username }}
                      </option>
                    </b-form-select>
                  </b-form-group>
                </div>
              </b-col>

              <b-col>
                <div class="form-group">
                  <b-form-group
                      label-for="select"
                  >
                    <b-form-select v-model="statusSearchKeys" multiple :select-size="3" >
                      <option value="null">-- 対応状況 --</option>
                      <option
                          v-for="(option) in statusOptions"
                          :key="option.text"
                          :value="option.value"
                      >{{ option.text }}
                      </option>
                    </b-form-select>
                  </b-form-group>
                </div>
              </b-col>
              <b-col>
                <div class="form-group">
                  <b-form-group
                      label=""
                      label-align="left"
                      label-for="per-page-select"
                      style="margin-left:50%;width:50%"
                  >
                    <b-form-select
                        id="per-page-select"
                        v-model="perPage"
                        :options="pageOptions"
                        @change="initDetailItem"

                    ></b-form-select>
                  </b-form-group>
                </div>
              </b-col>
            </b-row>
            <b-row>
              <div class="table-responsive">
                <b-table
                    :current-page="currentPage"
                    :fields="visibleFields"
                    :filter="filter"
                    :filter-function="tableFilter"
                    :items="allItems"
                    :per-page="perPage"
                    :select-mode="selectMode"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :sticky-header=false
                    head-variant="light"
                    outlined
                    primary-key="url"
                    responsive="lg"
                    selectable
                    selected-variant="success"
                    show-empty

                    style="width:100%"
                    @filtered="onFiltered"
                    @row-clicked="onRowSelected"
                >
                  <template #cell(index)="row">
                    {{ row.index + 1 }}
                  </template>
                  <template #cell(selected)="{ rowSelected }">
                    <template v-if="rowSelected">
                      <span aria-hidden="true" style="color:#5c1515;">&check;</span>
                      <span class="sr-only">Selected</span>
                    </template>
                    <template v-else>
                      <span aria-hidden="true">&nbsp;</span>
                      <span class="sr-only">Not selected</span>
                    </template>
                  </template>
                  <template #cell(vendor)="row">
                    <template v-if="row.value.length > 15">
                      <span v-b-popover.hover.focus="row.value">{{ row.value.substring(0, 15) }}...</span>
                    </template>
                    <template v-else>
                      <span>{{ row.value }}</span>
                    </template>
                  </template>
                  <template #cell(customer_name)="row">
                    <template v-if="row.value.length > 30">
                      <span v-b-popover.hover.focus="row.value">{{ row.value.substring(0, 30) }}...</span>
                    </template>
                    <template v-else>
                      <span>{{ row.value }}</span>
                    </template>
                  </template>

                  <template #cell(description)="row">
                    <span>{{ row.value }}</span>
                  </template>
                  <template #cell(severity)="row">
                    <span>{{ row.value }}</span>
                  </template>

                  <template #cell(managedAgent)="row">
                    <span>{{ row.value.name }}</span>
                  </template>

                  <template #cell(allowedActions)="row">
                    <span>{{ row.value }}</span>
                  </template>

                  <template #cell(category)="row">
                    <span>{{ row.value }}</span>
                  </template>

                  <template #cell(product)="row">
                    <span>{{ row.value }}</span>
                  </template>


                  <template #cell(status)="row">
                    <b-button :variant="statusColor[row.value]" class="p-sm-1 px-sm-1 btn-xs" style="width: 100%">
                      {{ statusText[row.value] }}
                    </b-button>
                  </template>
                  <template #cell(last_behavior_timestamp)="row">
                    <span v-b-popover.hover.focus="row.value">{{ row.value | dateFormat }}</span>
                  </template>
                </b-table>
              </div>
            </b-row>
            <b-row>

              <download-excel
                  :data="formattedDataCsv"
                  :fields="json_fields"
                  class="export-excel-wrapper"
                  :name="fileName"
                  type="csv"
                  worksheet="アラートレポート(sophos)"
              >
                <el-button type="primary" plain>レポート出力<i class="el-icon-download el-icon--right" ></i></el-button>
              </download-excel>

              <b-col></b-col>
              <b-col class="my-0">
                <b-pagination
                    v-model="currentPage"
                    :per-page="perPage"
                    :total-rows="totalRows"
                    align="left"
                    class="my-0"
                    size="md"
                    @page-click="initDetailItem"
                ></b-pagination>
              </b-col>
            </b-row>
          </div>
        </Widget>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <div v-if="isShow">
          <Widget class="mx-auto" customHeader name="select" title="<h3 class='mt-0 center'></h3>">
            <b-alert v-if="errorMessage!==''" :variant="messageColor" class="m-8" show><h4>{{ errorMessage }}</h4>
            </b-alert>
            <b-form>
              <table class="table table-lg mb-0">
                <tr>
                  <td class="title-class-name">検知時間</td>
                  <td class="data-cell-class-name1">{{ detailItem.last_behavior_timestamp| dateFormat }}</td>
                </tr>
                <tr>
                  <td class="title-class-name">ベンダー</td>
                  <td class="data-cell-class-name1">{{ detailItem.vendor }}</td>
                </tr>
              </table>

              <table class="table table-lg mb-0">
                <tr>
                  <td class="title-class-name">自動判断の結果（悪意の可能性）</td>
                  <td class="data-cell-class-name1">{{ maliciousText[detailItem.malicious] }}
                  </td>
                </tr>
                <tr>
                  <td class="title-class-name">対応者</td>
                  <td class="data-cell-class-name1">
                    <div class="form-group  border-info">
                      <b-form-select
                          id="soc_user_email_select"
                          v-model="detailItem.soc_user_email"
                          :options="socUserOptions"
                          return-object
                          text-field="username"
                          value-field="email"
                          required
                      ></b-form-select>

                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="title-class-name">対応理由</td>
                  <td class="data-cell-class-name1">
                    <b-form-input type="text" id="reason" ref="reason" v-model="detailItem.reason"
                                  :placeholder="detailItem.reason " autocomplete="reason" class="form-control  border-info"
                                  name="reason" required
                    ></b-form-input>
                  </td>
                </tr>

                <tr>
                  <td class="title-class-name">対応状況</td>
                  <td class="data-cell-class-name1">
                    <b-form-radio-group
                        v-model="detailItem.status"
                        :options="radioStatus"
                        class="mb-3 "
                        disabled-field="notEnabled"
                        required
                        text-field="name"
                        value-field="item"
                    ></b-form-radio-group>

                  </td>
                </tr>
                <tr>
                  <td class="title-class-name">対応者のコメント</td>
                  <td class="data-cell-class-name1">
                      <input id="comment" ref="comment" v-model="detailItem.comment"
                           :placeholder="detailItem.comment" autocomplete="comment" class="form-control border-info"
                           name="comment"
                           required type="text"/>
                  </td>
                </tr>

                <tr>
                  <td class="title-class-name">対応者の判断結果（feedback）</td>
                  <td class="data-cell-class-name1">
                    <b-form-radio-group
                        v-model="detailItem.feedback"
                        :options="radioFeedback"
                        class="mb-3  border-info"
                        disabled-field="notEnabled"
                        required
                        text-field="name"
                        value-field="item"
                    ></b-form-radio-group>
                  </td>
                </tr>

                <tr>
                  <td class="title-class-name"></td>
                  <td class="data-cell-class-name1">
                    <div>
                      <b-button id="update" type="submit" variant="success" @click="onSubmit" :disabled="iShowBtn===true">更新</b-button>
                      <b-button class="btn" variant="success" @click="onResend">メール作成</b-button>
                    </div>
                  </td>
                </tr>
              </table>

            </b-form>
          </Widget>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>


import Widget from '@/components/Widget/Widget'
import dayjs from 'dayjs'
import api from "@/api"

export default {

  name: 'AlertsSophos',
  components: {Widget,},

  computed: {
    fileName() {
      const now = new Date();
      const datePart = `${now.getFullYear()}-${(now.getMonth() + 1).toString().padStart(2, '0')}-${now.getDate().toString().padStart(2, '0')}`;
      const timePart = `${now.getHours().toString().padStart(2, '0')}-${now.getMinutes().toString().padStart(2, '0')}`;
      return `alert_sophos_report_${datePart}_${timePart}.csv`;
    },
    formattedDataCsv() {
      //console.log("formattedDataCsv " );
      const isValueInSelected = (selectedValues, value) => {
        if (!selectedValues || !selectedValues.length ||
            (selectedValues.length === 1 && selectedValues[0] === "null")) return true;
        selectedValues = selectedValues.filter(val => val !== "null")
        return selectedValues.map(val => val.toLocaleLowerCase()).includes(String(value).toLocaleLowerCase())
      }
      let items = this.allItems.filter(item =>{

        let filter_vendor = isValueInSelected(this.vendorSearchKeys, item.vendor)
        let filter_name = isValueInSelected(this.nameSearchKeys, item.customer_name)
        let filter_severity = isValueInSelected(this.severitySearchKeys, item.severity)
        let filter_soc_user_name = isValueInSelected(this.socUserNameSearchKeys, item.soc_user_name)
        let filter_status = isValueInSelected(this.statusSearchKeys, item.status)
        let result = filter_vendor && filter_name && filter_severity && filter_soc_user_name && filter_status

        return result;

      })
      return items.map(item => {
        return {
          last_behavior_timestamp: dayjs(item.last_behavior_timestamp).format('YYYY-MM-DD HH:mm:ss'),
          vendor: item.vendor,
          customer_name: item.customer_name,
          user_name: item.user_name,
          severity: item.severity,
          description: item.description,
          category: item.category,
          product: item.product,
          managedAgent: item.managedAgent,
          allowedActions: item.allowedActions,
        }
      })
    },
    visibleFields() {
      return this.fields.filter(field => field.visible)
    },
    filter: function () {
      return [this.vendorSearchKeys, this.nameSearchKeys, this.statusSearchKeys, this.socUserNameSearchKeys, this.severitySearchKeys]
    },

  },
  data() {
    return {
      mailHref: '',
      mailMessage: '',
      mailStyle: 'display: none',
      eMailTo: [],
      vendorSearchKeys: null,
      vendorOptions: [],
      nameSearchKeys: null,
      customerNameOptions: [],
      socUserNameSearchKeys: null,
      socUserOptions: [],
      allSocUserOptions: [],
      severitySearchKeys: ['MEDIUM', 'HIGH', 'CRITICAL'],
      severityOptions: [],
      statusSearchKeys: null,
      statusOptions: [],
      iShowBtn: false,
      lIndex: 0,
      messageColor: '',
      errorMessage: '',
      selectMode: 'single',//'multi'
      selected: {},
      statusText: ['NEW', 'ASSIGNED', 'IN-PROCESS', 'ESCALATED', 'CLOSED', 'IGNORE'],
      maliciousText: ['低い', '高い'],
      statusColor: ['warning', 'info', 'success', 'primary', 'secondary','secondary'],
      aResultText: ['Low', 'High'],
      aResultColor: ['warning', 'danger'],
      sortBy: 'last_behavior_timestamp',
      sortDesc: true,
      allItems: [],
      json_fields: {
        'DETECT TIME': 'last_behavior_timestamp',
        'VENDOR': 'vendor',
        'COMPANY NAME': 'customer_name',
        'SEVERITY': 'severity',
        'DESCRIPTION': 'description',
        'ALLOWEDACTIONS': 'allowedActions',
        'MANAGEDAGENT': 'managedAgent',
        'CATEGORY': 'category',
        'PRODUCT': 'product',
      },
      fields: [
        {key: 'selected', label: '', sortable: false, visible: true, thStyle: {width: "5px"},},
        {key: 'id', label: 'No.', sortable: true, class: 'text-center', visible: false,},//PK
        {key: 'last_behavior_timestamp', label: '検知時間', sortable: true, class: 'text-left', visible: true},
        {key: 'vendor', label: 'ベンダー', sortable: true, class: 'text-left', visible: false},
        {key: 'customer_name', label: 'COMPANY NAME', sortable: true, class: 'text-left', visible: true},
        {key: 'customer_id', label: 'COMPANY ID', sortable: true, class: 'text-left', visible: false},
        {key: 'severity', label: 'SEVERITY', sortable: true, class: 'text-left', visible: true},
        {key: 'description', label: 'description', sortable: false, class: 'text-left',visible: true},
        {key: 'allowedActions', label: 'allowedActions', sortable: false, class: 'text-left',visible: true},
        {key: 'managedAgent', label: 'managedAgent', sortable: false, class: 'text-left',visible: true},
        {key: 'category', label: 'category', sortable: false, class: 'text-left',visible: true},
        {key: 'product', label: 'product', sortable: false, class: 'text-left',visible: true},
        {key: 'soc_user_name', label: '対応者', sortable: true, class: 'text-left', visible: true},
        {key: 'soc_user_email', label: '対応者メール', sortable: true, class: 'text-left', visible: false},
        {key: 'status', label: '対応状況', sortable: true, class: 'text-center', visible: true},
        {key: 'comment', label: '対応者のコメント', sortable: false, class: 'text-left'},
      ],

      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 20, 30, {value: 100, text: "Show a lot"}],
      isShow: false,
      detailItem: {},
      startDate: null,
      endDate: null,
      selectOwnerList: [],
      radioStatus: [
        {item: '0', name: 'NEW'},
        {item: '1', name: 'ASSIGNED'},
        {item: '2', name: 'IN-PROCESS'},
        {item: '3', name: 'ESCALATED'},
        {item: '4', name: 'CLOSED'},
        {item: '5', name: 'IGNORE'},
      ],
      radioFeedback: [
        {item: '1', name: '悪意'},
        {item: '0', name: '正常'},
      ]
    }
  },

  mounted() {
    this.startDate = this.oneWeekAgo()
    this.endDate = new Date().toISOString().slice(0, 10)
    this.onSearch()
  },

  methods: {
    startDownload(){
      alert('show loading');
    },
    finishDownload(){
      alert('hide loading');
    },
    oneWeekAgo() {
      let date = new Date();
      date.setDate(date.getDate()- 6);
      return date.toISOString().slice(0, 10);
    },

    tableFilter(row){
      const isValueInSelected = (selectedValues, value) => {
        if (!selectedValues || !selectedValues.length ||
            (selectedValues.length === 1 && selectedValues[0] === "null")) return true;
        selectedValues = selectedValues.filter(val => val !== "null")
        return selectedValues.map(val => val.toLocaleLowerCase()).includes(String(value).toLocaleLowerCase())
      }
      let filter_vendor = isValueInSelected(this.vendorSearchKeys, row.vendor)
      let filter_name = isValueInSelected(this.nameSearchKeys, row.customer_name)
      let filter_severity = isValueInSelected(this.severitySearchKeys, row.severity)
      let filter_soc_user_name = isValueInSelected(this.socUserNameSearchKeys, row.soc_user_name)
      let filter_status = isValueInSelected(this.statusSearchKeys, row.status)
      return filter_vendor && filter_name && filter_severity && filter_soc_user_name && filter_status
    },
    onResend() {
      const id = this.selected.id
      api.put('/api/alerts-sophos/email/' + id, {
        user_id: window.localStorage.getItem('user_id')
      }).then(res => {

        let status = res.data.statusCode
        let result = res.data.body.result

        if (status === 200 && result === 'success') {
          let mailItem = res.data.body.data['mailItem']
          this.isShow = true
          let data = new Blob([mailItem])
          let link1 = document.createElement('a')
          let mailFile = 'alert_' + dayjs().format("YYYYMMDDhhss") + '.eml'
          link1.setAttribute('download', mailFile)
          link1.setAttribute('href', window.webkitURL.createObjectURL(data))
          link1.click()
          this.errorMessage = "メール作成した"
          this.messageColor = "success"

        }
        if (status === 200 && result === 'failure') {
          this.errorMessage = res.data.body.error
          this.messageColor = "warning"
          this.isShow = true
        }
      })
          .catch(error => {
            console.log(error)
            this.errorMessage = error
            this.messageColor = "danger"
            this.isShow = true
          })

    },

    initDetailItem() {
      this.isShow = false
      this.errorMessage = ""
      this.lIndex = 0

    },


    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
      this.errorMessage = ''
      this.initDetailItem()
      this.detailItem = {}

    },

    onRowSelected(item, index) {

      let oldItem = {}
      this.selected = item
      this.initDetailItem()

      oldItem = this.allItems.find(v => v.id === item.id)
      this.detailItem = {
        last_behavior_timestamp: oldItem.last_behavior_timestamp,
        vendor: oldItem.vendor,
        severity: oldItem.severity,
        description: oldItem.description,
        category: oldItem.category,
        product: oldItem.product,
        malicious: oldItem.malicious,
        allowedActions: oldItem.allowedActions,
        managedAgent: oldItem.managedAgent,
        soc_user_name: oldItem.soc_user_name ? oldItem.soc_user_name : '',
        soc_user_email: oldItem.soc_user_email ? oldItem.soc_user_email : '',
        reason: oldItem.reason ? oldItem.reason : '',
        comment: oldItem.comment ? oldItem.comment : '',
        feedback: oldItem.feedback ? oldItem.feedback : '',
        status: oldItem.status ? oldItem.status : ''
      }
      this.isShow = true
      if (oldItem.status==='4'){
        this.iShowBtn = true
      }else{
        this.iShowBtn = false
      }
      this.lIndex = index
      this.$nextTick(() => {
        this.$refs.owner.focus()
      })

    },
    onSubmit(event) {
      let newItem = {}
      this.initDetailItem()
      const id = this.selected.id
      event.preventDefault()
      api.put('/api/alerts-sophos/update/' + id, {
        reason: this.detailItem.reason,
        soc_user_email: this.detailItem.soc_user_email,
        comment: this.detailItem.comment,
        status: this.detailItem.status,
        feedback: this.detailItem.feedback
      }).then(res => {

        let status = res.data.statusCode
        let result = res.data.body.result
        if (status === 200 && result === 'success') {
          newItem = res.data.body.data.detailItem
          const index = this.allItems.findIndex(v => v.id === newItem.id)
          this.allItems[index]['last_behavior_timestamp'] = newItem.last_behavior_timestamp
          this.allItems[index]['host_name'] = newItem.host_name
          this.allItems[index]['host_type'] = newItem.host_type
          this.allItems[index]['vendor'] = newItem.vendor
          this.allItems[index]['customer_name'] = newItem.customer_name
          this.allItems[index]['severity'] = newItem.severity

          this.allItems[index]['description'] = newItem.description
          this.allItems[index]['malicious'] = newItem.malicious


          this.allItems[index]['allowedActions'] = newItem.allowedActions
          this.allItems[index]['managedAgent'] = newItem.managedAgent

          this.allItems[index]['reason'] = newItem.reason
          this.allItems[index]['soc_user_email'] = newItem.soc_user_email
          this.allItems[index]['soc_user_name'] = newItem.soc_user_name
          this.allItems[index]['status'] = newItem.status
          this.allItems[index]['comment'] = newItem.comment
          this.allItems[index]['feedback'] = newItem.feedback
          this.isShow = true
          this.errorMessage = "正常に更新しました。"
          this.messageColor = "success"

        }
        if (status === 200 && result === 'failure') {
          this.errorMessage = res.data.body.error
          this.messageColor = "warning"
          this.isShow = true
        }
      })
          .catch(error => {
            console.log(error)
            this.errorMessage = error
            this.messageColor = "danger"
            this.isShow = false
          })
    },
    onSearch: function () {
      this.errorMessage = ''
      this.allItems = []
      this.isShow = false
      this.vendorOptions = []
      this.customerNameOptions = []
      this.socUserOptions = []
      this.severityOptions = []
      this.statusOptions = []
      this.vendorSearchKeys=null,
      this.nameSearchKeys=null,
      this.socUserNameSearchKeys= null,
      //this.severitySearchKeys=null,
      this.statusSearchKeys=null,

      api.post('/api/alerts-sophos/list', {
        startDate: this.startDate,
        endDate: this.endDate,
        severity: this.severitySearchKeys,
        page_number: this.currentPage,
        page_size: this.perPage,
      }).then (res => {
        let status = res.data.statusCode
        let result = res.data.body.result

        if (status === 200 && result === 'success') {
          this.allItems = res.data.body.data['allItems']
          this.vendorOptions = res.data.body.data['vendorOptions']
          this.customerNameOptions = res.data.body.data['customerNameOptions']
          this.socUserOptions = res.data.body.data['socUserOptions']
          this.allSocUserOptions=res.data.body.data['allSocUserOptions']
          this.severityOptions = res.data.body.data['severityOptions']
          this.statusOptions = res.data.body.data['statusOptions']
          this.totalRows = this.allItems.length
        }
        if (status === 200 && result === 'failure') {
          this.errorMessage = res.data.body.error
          this.messageColor = "warning"
        }
      }).catch(error => {
            console.log(error)
            this.errorMessage = error
            this.messageColor = "danger"
            this.isShow = false
      })
    }
  },

}
</script>
<style lang="scss" scoped src="./Alerts.scss"/>