<template>
  <div>
    <b-breadcrumb>
      <b-breadcrumb-item active>ダッシュボード </b-breadcrumb-item>
    </b-breadcrumb>
    <b-row>
      <b-col lg="4" sm="1" xs="12">
        <div class="table-responsive">
          <Widget class="h-100 mb-0" title="">
            <section class="charts">
              <h3><!-- <b-icon icon="clock-fill"></b-icon>-->アラート検知数
              </h3>
              <vue-highcharts ref="lineCharts" :options="options"></vue-highcharts>
            </section>
          </Widget>
        </div>
      </b-col>
      <b-col lg="4" sm="1" xs="12">
        <div class="pb-xlg h-100">
          <Widget class="h-100 mb-0" title="">
            <span class="m-2" style="font-size: 2rem">API連携数 </span>
            <p class="text-muted d-flex flex-wrap"></p>


            <div class="d-flex flex-wrap justify-content-between">
              <div class="mt">
                <h4>{{ (apiStartCount + apiStopCount).toLocaleString() }}</h4>
                <p class="text-muted mb-0 mr"><small>連携数合計</small></p>
              </div>
              <div class="mt">
                <h4>{{ apiStartCount.toLocaleString() }}</h4>
                <p class="text-muted mb-0 mr"><small>接続中</small></p>
              </div>
              <div class="mt">
                <h4>{{ apiStopCount.toLocaleString() }}</h4>
                <p class="text-muted mb-0"><small>接続失敗</small></p>
              </div>
            </div>
          </Widget>
        </div>
      </b-col>
      <b-col lg="4" sm="1" xs="12">
        <div class="pb-xlg h-100">
          <Widget class="h-100 mb-0" title="">

            <h3><!--<b-icon icon="bell-fill"  >
            </b-icon>-->セキュリティー情報</h3>

            <!--<div id="icatwidthc" title="500" ></div>
            <div id="icatheightc" title="300" ></div>

            <span id='ipa_rss_feed_icatw2'></span>
            <span id='ipa_rss_feed_icatwc'></span>-->
            <div class="news-list">
              <li v-for="item in items" :key="item.id">
                <a target="_blank" v-bind:href="item.link">
                  <span class="title">{{ item.title }}</span>
                  <span class="img">{{ item.image }}</span>
                  <span class="time">{{ item.time }}</span>
                </a>
              </li>
            </div>

          </Widget>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col lg="6" sm="1" xs="12">
        <div class="table-responsive">
          <Widget class="h-100 mb-0" title="">
            <b-row>
              <b-col>
                <h3><!--<b-icon icon="alarm" ></b-icon>-->Assigned ALERT
                </h3>
              </b-col>

              <b-form-group
                  label=""
                  label-for="per-page-select1"
                  class="mb-0"
                  label-align-sm="right"
                  label-size="sm"
                  label-cols-sm="11"
              >
                <b-form-select
                    id="per-page-select1"
                    v-model="perPage1"
                    :options="pageOptions1"
                    size="sm"

                ></b-form-select>
              </b-form-group>

            </b-row>

            <b-table
                :current-page="currentPage1"
                :items="myItems"
                :fields="fields"
                responsive="sm"
                :per-page="perPage1"
                :sort-by.sync="sortBy1"
                :sort-desc.sync="sortDesc1"
                show-empty
                style="width:100%"
                @filtered="onFiltered1"
            >
              <!--<template #cell(index)="data">
                 {{ data.index + 1 }}
               </template>-->
              <template #cell(severity)="row">
                <span>{{ row.value }}</span>
                <!--<b-badge :variant="levelColor[data.value]">{{levelText[data.value]}}</b-badge>-->
              </template>
              <template #cell(vendor)="row">
                <template v-if="row.value.length > 20">
                  <span v-b-popover.hover.focus="row.value">{{ row.value.substring(0, 20) }}...</span>
                </template>
                <template v-else>
                  <span>{{ row.value }}</span>
                </template>
              </template>
              <template #cell(last_behavior_timestamp)="row">
                <span v-b-popover.hover.focus="row.value">{{ row.value.substring(0, 19) }}</span>
              </template>
              <template #cell(customer_name)="row">
                <template v-if="row.value.length > 20">
                  <span v-b-popover.hover.focus="row.value">{{ row.value.substring(0, 20) }}...</span>
                </template>
                <template v-else>
                  <span>{{ row.value }}</span>
                </template>
              </template>
              <template #cell(comment)="row">
                <template v-if="row.value.length > 7">
                  <span v-b-popover.hover.focus="row.value">{{ row.value.substring(0, 7) }}...</span>
                </template>
                <template v-else>
                  <span>{{ row.value }}</span>
                </template>
              </template>
              <template #cell(status)="row">
                <strong>
                  <b-badge :variant="statusColor[row.value]" style="width: 80px">{{ statusText[row.value] }}</b-badge>
                </strong>
                <!--<span>{{statusText[data.value]}}</span>-->
              </template>
            </b-table>
            <b-row>
              <b-col>
              </b-col>
              <b-col class="my-1" md="6" sm="7">
                <b-pagination
                    v-model="currentPage1"
                    :total-rows="totalRows1"
                    :per-page="perPage1"
                    align="right"
                    size="md"
                    class="my-0"
                ></b-pagination>
              </b-col>
            </b-row>
          </Widget>
        </div>
      </b-col>
      <b-col lg="6" sm="1" xs="12">
        <div class="pb-xlg h-100">
          <Widget class="h-100 mb-0" title="">
            <b-row>
              <b-col>
                <h3><!--<b-icon icon="alarm-fill" ></b-icon>-->New ALERT
                </h3>
              </b-col>
              <b-form-group
                  label=""
                  label-for="per-page-select2"
                  class="mb-0"
                  label-align-sm="right"
                  label-size="sm"
                  label-cols-sm="11"
              >
                <b-form-select
                    id="per-page-select2"
                    v-model="perPage2"
                    :options="pageOptions2"
                    size="sm"

                ></b-form-select>
              </b-form-group>

            </b-row>

            <b-table
                :fields="fields"
                :items="allNewItems"
                show-empty
                :current-page="currentPage2"
                :per-page="perPage2"
                :sort-by.sync="sortBy2"
                :sort-desc.sync="sortDesc2"
                style="width:100%"
                responsive="sm"
                @filtered="onFiltered2"
            >
              <template #cell(severity)="row">
                <!--<b-badge :variant="levelColor[data.value]">{{levelText[data.value]}}</b-badge>-->
                <span>{{ row.value }}</span>
              </template>
              <template #cell(vendor)="row">
                <template v-if="row.value.length > 20">
                  <span v-b-popover.hover.focus="row.value">{{ row.value.substring(0, 20) }}...</span>
                </template>
                <template v-else>
                  <span>{{ row.value }}</span>
                </template>
              </template>
              <template #cell(customer_name)="row">
                <template v-if="row.value.length > 20">
                  <span v-b-popover.hover.focus="row.value">{{ row.value.substring(0, 20) }}...</span>
                </template>
                <template v-else>
                  <span>{{ row.value }}</span>
                </template>
              </template>
              <template #cell(last_behavior_timestamp)="row">
                <span v-b-popover.hover.focus="row.value">{{ row.value.substring(0, 19) }}</span>
              </template>
              <template #cell(status)="row">
                <strong>
                  <b-badge :variant="statusColor[row.value]" style="width: 80px">{{ statusText[row.value] }}</b-badge>
                </strong>
              </template>
            </b-table>
            <b-row>
              <b-col>
              </b-col>

              <b-col class="my-1" md="6" sm="7">
                <b-pagination
                    v-model="currentPage2"
                    :total-rows="totalRows2"
                    :per-page="perPage2"
                    align="end"
                    size="md"
                    class="my-0"
                ></b-pagination>
              </b-col>
            </b-row>
          </Widget>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Widget from '@/components/Widget/Widget'

import VueHighcharts from "vue2-highcharts"
import Exporting from "highcharts/modules/exporting"
import Highcharts from "highcharts"
import api from "@/api"


Exporting(Highcharts)

export default {
  name: 'Dashboard',
  components: {Widget, VueHighcharts,},
  computed: {
    visibleFields() {
      return this.fields.filter(field => field.visible)
    },
  },
  data() {
    return {
      count_new: '',
      options: {
        chart: {
          type: "spline"
        },
        title: {
          text: ""
        },
        subtitle: {
          text: ""
        },
        xAxis: {
          categories: chart_x_axis
        },
        yAxis: {
          title: {
            text: "Number"
          },
          labels: {
            formatter: function () {
              return this.value;
            }
          }
        },
        series: [],
        tooltip: {
          headerFormat: '{series.name}<br>',
          shared: true
        },
        credits: {
          enabled: false
        },
        plotOptions: {
          spline: {
            marker: {
              radius: 4,
              lineColor: "#666666",
              lineWidth: 1
            }
          }
        },
      },
      Highcharts: Highcharts,

      apiStartCount: 0,
      apiStopCount: 0,
      apiRate: 0,
      items: [],
      statusText: ['NEW', 'ASSIGNED', 'IN-PROCESS', 'ESCALATED', 'CLOSED'],
      statusColor: ['warning', 'info', 'success', 'primary', 'inverse'],

      levelColor: ['success', 'warning', 'danger'],
      levelText: ['Low', 'Medium', 'High'],
      sortBy1: 'last_behavior_timestamp',
      sortDesc1: true,
      sortBy2: 'last_behavior_timestamp',
      sortDesc2: true,
      myItems: [],
      allNewItems: [],
      fields: [
        //'index',
        {key: 'last_behavior_timestamp', label: '検知時間', sortable: true, class: 'text-left', visible: true},
        {key: 'vendor', label: 'ベンダー', sortable: true, class: 'text-left', visible: true},
        {key: 'customer_name', label: 'COMPANY NAME', sortable: true, class: 'text-left', visible: true},
        {key: 'severity', label: 'SEVERITY', sortable: true, class: 'text-left', visible: true},
        {key: 'soc_user_name', label: '対応者', sortable: true, class: 'text-left', visible: true},
        {key: 'status', label: '対応状況', sortable: true, class: 'text-center', visible: true},

      ],
      totalRows1: 1,
      currentPage1: 1,
      perPage1: 10,
      pageOptions1: [10, 20, 30, {value: 100, text: "Show a lot"}],
      totalRows2: 1,
      currentPage2: 1,
      perPage2: 10,
      pageOptions2: [10, 20, 30, {value: 100, text: "Show a lot"}],
      //alert table end
    }


  },

  async created() {
    await this.fetchData()
  },
  methods: {

    onFiltered1(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows1 = filteredItems.length
      this.currentPage1 = 1
    },
    onFiltered2(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows2 = filteredItems.length
      this.currentPage2 = 1
    },

    fetchData() {
      api.post('/api/dashboard/init', {
        username: window.localStorage.getItem('username'),
      }).then(res => {

        let status = res.data.statusCode
        let result = res.data.body.result
        if (status === 200 && result === 'success') {
          //console.log(res.data.body.data)
          this.apiStartCount = res.data.body.data.apiStartCount
          this.apiStopCount = res.data.body.data.apiStopCount
          this.items = res.data.body.data.items
          this.myItems = res.data.body.data.myItems
          this.allNewItems = res.data.body.data.allNewItems
          let chart_data_mine = {
            name: '担当数',
            color: '#2986cc',
            lineColor: '#2986cc',
            data: []
          }
          let chart_data_total = {
            name: '全体',
            color: '#6fcd98',
            lineColor: '#6fcd98',
            data: []
          }

          chart_data_mine.data = res.data.body.data.chart_data_mine;
          chart_data_total.data = res.data.body.data.chart_data_total;

          let lineCharts = this.$refs.lineCharts;
          lineCharts.delegateMethod("showLoading", "Loading...");
          setTimeout(() => {
            lineCharts.getChart().xAxis[0].setCategories(res.data.body.data.chart_x_axis);
            lineCharts.addSeries(chart_data_mine);
            lineCharts.addSeries(chart_data_total);
            lineCharts.hideLoading();
          }, 1000);

          this.apiRate = (res.data.body.data.apiStartCount / (res.data.body.data.apiStartCount + res.data.body.data.apiStopCount)).toFixed(2) * 100

          this.totalRows1 = this.myItems.length
          this.totalRows2 = this.allNewItems.length
          this.count_new = res.data.body.data.count_new
          window.sessionStorage.setItem('count_new', this.count_new)
        }
        if (status === 200 && result === 'failure') {
          this.errorMessage = res.data.body.error
          this.messageColor = "warning"
        }
      }).catch(error => {
        console.log(error)
        this.errorMessage = error
        this.messageColor = "danger"
      })
    },
  },
  mounted() {
    // eslint-disable-next-line no-irregular-whitespace
   //this.count_new  = window.sessionStorage.getItem("count_new")
  }

}
// eslint-disable-next-line no-unused-vars
let chart_x_axis = []
</script>
<style lang="scss" scoped src="./Dashboard.scss"/>