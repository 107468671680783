<template>
  <section ref="widget" :class="{ widget: true, className }" v-bind="dataWidgster">
    <h5 v-if="title && typeof title === 'string' && !customHeader" class="title">{{ title }}</h5>
    <header v-if="title && customHeader" class="title" v-html="title"/>
    <div v-if="!customControls && mainControls"
         class="widgetControls widget-controls">
      <a v-if="settings" href="#"><i class="la la-cog"/></a>
      <a v-if="settingsInverse" class="bg-gray-transparent inverse" href="#">
        <i class="la la-cog text-white"/>
      </a>
      <a v-if="refresh" :id="`reloadId-${randomId}`" data-widgster="load" href="#">
        <strong v-if="typeof refresh === 'string'" class="text-gray-light">{{ refresh }}</strong>
        <i v-else class="la la-refresh"/>
        <b-tooltip
            v-if="showTooltip"
            :placement="tooltipPlacement"
            :target="`reloadId-${randomId}`"
        >Reload
        </b-tooltip>
      </a>
      <a v-if="fullscreen" :id="`fullscreenId-${randomId}`" data-widgster="fullscreen" href="#">
        <i class="glyphicon glyphicon-resize-full"/>
        <b-tooltip
            v-if="showTooltip"
            :placement="tooltipPlacement"
            :target="`fullscreenId-${randomId}`"
        >Fullscreen
        </b-tooltip>
      </a>
      <a v-if="fullscreen" :id="`restoreId-${randomId}`" data-widgster="restore" href="#">
        <i class="glyphicon glyphicon-resize-small"/>
        <b-tooltip
            v-if="showTooltip"
            :placement="tooltipPlacement"
            :target="`restoreId-${randomId}`"
        >Restore
        </b-tooltip>
      </a>
      <span v-if="collapse">
        <a :id="`collapseId-${randomId}`" data-widgster="collapse" href="#">
          <i class="la la-angle-down"/>
          <b-tooltip
              v-if="showTooltip"
              :placement="tooltipPlacement"
              :target="`collapseId-${randomId}`"
          >Collapse
          </b-tooltip>
        </a>
      </span>
      <span v-if="collapse">
        <a :id="`expandId-${randomId}`" data-widgster="expand" href="#">
          <i class="la la-angle-up"/>
          <b-tooltip
              v-if="showTooltip"
              :placement="tooltipPlacement"
              :target="`expandId-${randomId}`"
          >Expand
          </b-tooltip>
        </a>
      </span>
      <a v-if="close" :id="`closeId-${randomId}`" data-widgster="close" href="#">
        <strong v-if="typeof refresh === 'string'" class="text-gray-light">{{ close }}</strong>
        <i v-else class="la la-remove"/>
        <b-tooltip
            v-if="showTooltip"
            :placement="tooltipPlacement"
            :target="`closeId-${randomId}`"
        >Close
        </b-tooltip>
      </a>
    </div>
    <div v-if="customControls" class="widgetControls widget-controls" v-html="customControls"/>
    <div :class="`widgetBody widget-body ${bodyClass}`">
      <slot></slot>
    </div>
  </section>
</template>

<script>
import $ from 'jquery';
import 'imports-loader?window.jQuery=jquery,this=>window!widgster'; // eslint-disable-line

export default {
  name: 'Widget',
  props: {
    customHeader: {type: Boolean, default: false},
    tooltipPlacement: {default: 'top'},
    showTooltip: {type: Boolean, default: false},
    close: {type: [Boolean, String], default: false},
    fullscreen: {type: [Boolean, String], default: false},
    collapse: {type: [Boolean, String], default: false},
    settings: {type: [Boolean, String], default: false},
    settingsInverse: {type: Boolean, default: false},
    refresh: {type: [Boolean, String], default: false},
    className: {default: ''},
    title: {default: ''},
    customControls: {default: null},
    bodyClass: {default: ''},
    options: {default: () => ({})},
    dataWidgster: {type: Object, default: () => ({})},
  },
  computed: {
    randomId() {
      return Math.floor(Math.random() * 100);
    },
    mainControls() {
      return !!(this.close || this.fullscreen || this.collapse
          || this.refresh || this.settings || this.settingsInverse);
    },
  },
  mounted() {
    const {options} = this;
    options.bodySelector = '.widget-body';
    $(this.$refs.widget).widgster(options);
  },
};
</script>

<style lang="scss" src="./Widget.scss"/>
