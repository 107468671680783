
<template>
  <router-view />
</template>

<script>
export default {
  name: 'App',

  created() {
    // この部分が created フックです。コンポーネントが作成された直後に実行されます。
    console.log('App created');
    // 例：APIからデータを取得する
    // this.fetchData();
    // 例：認証状態のチェック
    // if (!isAuthenticated()) {
    //   this.$router.push('/login');
    // }
  },
  methods: {
    // fetchData() {
    //   // APIからデータを取得する処理
    // }
  }
};
</script>

<style src="./styles/theme.scss" lang="scss" />
