<template>
  <div class="login-page">
    <b-container>
      <h1 class="logo">
                <span class="avatar thumb  mr-2">
                    <img class="rounded-right " src="../../assets/logo/logo_corp.svg" alt="..." />
                </span>
        <span style="color:#01ab86" >セキュアエッジ </span>
      </h1>
      <Widget name="login" class="mx-auto" title="<h3 class='mt-0 center'></h3>" customHeader>
        <div class="clearfix">
          <div class="clearfix">
            <h4 class="center">パスワードの設定</h4>
          </div>
          <p class="mb-2 px-2">
            新しく設定するパスワードを入力し、「パスワード変更」ボタンを押してください。
          </p>
          <p class="mb-8 px-sm-2 text-sm text-red-500">※セキュリティ上、ご登録メールアドレス以外に連絡することはできません</p>
          <p></p>

          <p class="same-account-explanation"></p>
        </div>

        <form class="mt" @submit.prevent="onReset">
          <b-alert v-if="errorMessage!==''" :show="!!errorMessage" :variant="messageColor" class="m-8"><h4>{{ errorMessage }}</h4></b-alert>
          <div class="form-group">
            <input v-model="password"  class="form-control no-border" ref="password"
                   required type="password" name="password" placeholder="新しいパスワード" autocomplete="password" />
          </div>

          <div class="form-group">
            <input v-model="password_confirm"  class="form-control no-border" ref="password_confirm"
                   required type="password" name="password_confirm" placeholder="新しいパスワード再入力" autocomplete="password_confirm" />
          </div>


          <div class="clearfix center">
            <b-button type="submit" size="lg" variant="success" > パスワード変更</b-button>
          </div>
          <br>
          <div class="clearfix center">
            <a class="btn-link" href="/">ログインページへ</a>
          </div>

        </form>
      </Widget>
    </b-container>
    <footer class="footer">
    </footer>
  </div>
</template>

<script>

import Widget from '@/components/Widget/Widget';
import api from "@/api";

export default {
  name: 'PasswordReset',
  components: {Widget},
  data() {
    return {
      messageColor: '',
      errorMessage: '',
      token: new URL(location.href).searchParams.get('token'),
      password: '',
      password_confirm: '',

    }
  },
  methods: {
    onReset: function () {
      api.post('/api/password-reset', {
        password_recover_token: this.token,
        password: this.password,
        password_confirm: this.password_confirm
      }).then (res => {

        let status =res.data.statusCode
        let result =res.data.body.result

        if (status===200 && result==='success'){

          this.errorMessage="ログイン画面にログインしてください。"
          this.messageColor="success"

        }
        if (status===200 && result==='failure'){
          this.errorMessage=res.data.body.error
          this.messageColor="warning"
        }
      })
          .catch(error => {
            console.log(error)
            this.errorMessage=error
            this.messageColor="danger"
          })
    }
  }
}
</script>

<style src="./Login.scss" lang="scss" scoped />
