<template>
  <div>
    <b-breadcrumb>
      <b-breadcrumb-item>API連携</b-breadcrumb-item>
      <b-breadcrumb-item active>API登録</b-breadcrumb-item>
    </b-breadcrumb>
     <b-row>
      <b-col xs="12">
        <Widget
            title="<h5> <span class='fw-semi-bold'> </span></h5>"
            bodyClass="widget-table-overflow"
            customHeader
        >
          <div class="table-responsive">
            <b-table
                style="width:100%"
                :items="allItems"
                :fields="visibleFields"
                :current-page="currentPage"
                :per-page="perPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                show-empty
                responsive="lg"
                @filtered="onFiltered"
                :select-mode="selectMode"
                selectable
                outlined
                head-variant="light"
                selected-variant="success"
                @row-clicked="onRowSelected"
                ref="selectableTable"
            >

              <template #cell(selected)="{ rowSelected }">
                <template v-if="rowSelected">
                  <strong><span style="color:#000000;" aria-hidden="true"><h4>&check;</h4></span></strong>
                  <span class="sr-only">Selected</span>
                </template>
                <template v-else>
                  <span aria-hidden="true">&nbsp;</span>
                  <span class="sr-only">Not selected</span>
                </template>
              </template>
              <template #cell(name)="row">
                <template v-if="row.value.length > 20">
                  <span  v-b-popover.hover.focus="row.value">{{row.value.substring(0,20)}}...</span>
                </template>
                <template v-else>
                  <span >{{row.value}}</span>
                </template>
              </template>
              <template #cell(status)="row">
                <b-button class="p-1 px-3 btn-xs" style="width: 80px" :variant="statusColor[row.value]">{{statusText[row.value]}}</b-button>
              </template>
              <template #cell(vendor)="row">
                <template v-if="row.value.length > 20">
                  <span  v-b-popover.hover.focus="row.value">{{row.value.substring(0,20)}}...</span>
                </template>
                <template v-else>
                  <span >{{row.value}}</span>
                </template>
              </template>
              <template #cell(key)="row">
                <template v-if="row.value.length > 30">
                  <span  v-b-popover.hover.focus="row.value">{{row.value.substring(0,30)}}...</span>
                </template>
                <template v-else>
                  <span >{{row.value}}</span>
                </template>
              </template>
              <template #cell(code)="row">
                <template v-if="row.value.length > 30">
                  <span  v-b-popover.hover.focus="row.value">{{row.value.substring(0,30)}}...</span>
                </template>
                <template v-else>
                  <span >{{row.value}}</span>
                </template>
              </template>
              <template #cell(delete)={rowSelected}>
                <template v-if="rowSelected">
                  <strong><span style="color:#ff0000;" aria-hidden="true"><a @click="dataDelete"><h4>&chi;</h4></a></span></strong>
                  <span class="sr-only">Selected</span>
                </template>
                <template v-else>
                  <span aria-hidden="true">&nbsp;</span>
                  <span class="sr-only">Not selected</span>
                </template>
              </template>
            </b-table>
          </div>
          <b-row>
            <b-col lg="1" class="pb-2"></b-col>
            <b-col lg="4" class="pb-2">
              <b-btn  @click="addDetail"><b-icon  icon="person-plus-fill"></b-icon></b-btn>
            </b-col>
            <b-col lg="3" class="pb-2">
              <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  align="left"
                  size="md"
                  class="mx-5"
                  @page-click="initDetailItem"
              ></b-pagination>
            </b-col>
            <b-col lg="4" class="pb-2" >
              <div class="left">
                <b-form-group
                    label=""
                    label-for="per-page-select"
                    label-align="left"
                    style="margin-left:70%;width:30%"
                >
                  <b-form-select
                      id="per-page-select"
                      v-model="perPage"
                      :options="pageOptions"
                      @change="initDetailItem"

                  ></b-form-select>
                </b-form-group>
              </div>
            </b-col>
          </b-row>
        </Widget>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <div v-if="isShowAll">
          <Widget name="select" class="mx-auto" title="<h3 class='mt-0 center'></h3>" customHeader>
            <b-alert v-if="errorMessage!==''" show :variant="messageColor" class="m-8"><h4>{{ errorMessage }}</h4></b-alert>
            <form @submit.prevent="onSubmit">
              <table class="table" v-if="isShow">

                <tr>
                  <td class="title-class-name">会社名</td>

                  <td class="data-cell-class-name1">
                    <div class="form-group">
                      <b-form-group
                          label-for="select"
                      >
                        <b-form-select v-model="detailItem.name" ref="name" required>
                          <option :value="null" >-- 会社名 --</option>
                          <option
                              v-for="(option, idx) in nameOptions"
                              :key="idx"
                              :value="option"

                          >{{ option }}
                          </option>
                        </b-form-select>
                      </b-form-group>
                    </div>

                  </td>
                </tr>

                <tr>
                  <td class="title-class-name">ベンダー</td>
                  <td class="data-cell-class-name1">
                    <div class="form-group">
                        <b-form-group
                            label-for="select"
                        >
                        <b-form-select v-model="detailItem.vendor" ref="vendor" required>
                          <option :value="null" >-- ベンダー --</option>
                          <option
                              v-for="(option, idx) in vendorOptions"
                              :key="idx"
                              :value="option.text">  {{ option.text }}
                          </option>
                        </b-form-select>
                      </b-form-group>
                    </div>
                   </td>
                </tr>
                <tr>
                  <td class="title-class-name">Client ID</td>
                  <td class="data-cell-class-name1">
                    <input v-model="detailItem.key"
                           class="form-control no-border"
                           ref="key"
                           type="text"
                           name="key"
                           placeholder="Client ID"
                           autocomplete="key"
                           pattern="^[0-9a-zA-Z-]{1,100}"
                           title="半角英数字1～100文字を入力してください。"
                           required
                    />
                  </td>
                </tr>
                <tr>
                  <td class="title-class-name">Secret</td>
                  <td class="data-cell-class-name1">
                    <input v-model="detailItem.code"
                           class="form-control no-border"
                           ref="code"
                           type="text"
                           name="code"
                           placeholder="Secret"
                           autocomplete="code"
                           required
                           pattern="^[0-9a-zA-Z]{1,100}"
                           title="半角英数字1～100文字を入力してください。"
                    />
                  </td>
                </tr>

                <tr class="text-muted">
                  <th></th>
                  <td>
                    <div class="center clearfix">
                      <b-button type="submit"   variant="success" class="p-1 px-3 btn-xs"  > {{ formTittle }} </b-button>
                    </div>
                  </td>
                  <td>
                  </td>
                </tr>

              </table>
            </form>
          </Widget>
        </div>
      </b-col></b-row>
  </div>
</template>

<script>

import Widget from '@/components/Widget/Widget'
import api from "@/api"

export default {
  name: 'ApiRegister',
  components: { Widget },
  computed: {
    visibleFields() {
      return this.fields.filter(field => field.visible)
    },

  },
  data () {
    return {
      nameSearchKey:null,
      lIndex:0,
      statusColor: ['warning','secondary'],
      statusText:['正常','異常'],
      selectMode: 'single',//'multi'
      selected: {},
      sortBy: 'id',
      sortDesc: true,
      allItems: [],
      fields: [
        { key: 'selected', label:'',visible: true,thStyle: { width: "0px" },},
        { key: 'id', label:'#',sortable: true ,class: 'text-center',visible: false,thStyle: { width: "0px" },},//PK
        { key: 'name', label:'会社名',sortable: true ,class: 'text-center',visible: true},
        { key: 'vendor', label:'ベンダー',sortable: true ,class: 'text-center',visible: true},
        { key: 'key', label:'Client ID',sortable: true ,class: 'text-center',visible: true},
        { key: 'code', label:'Secret',sortable: true ,class: 'text-center',visible: true},
        { key: 'update_at', label:'更新日時',sortable: true ,class: 'text-center',visible: true},
        { key: 'status', label:'ステータス',sortable: true ,class: 'text-center',visible: true},
        { key: 'delete', label:'',visible: true,thStyle: { width: "0px" },},
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [ 10, 20, 30,{ value: 100, text: "Show a lot" }],
      formTittle:'',
      detailItem: {},
      nameOptions:[],
      vendorOptions:[],
      isShow: false,
      isShowAll: false,
      errorMessage: "",
      messageColor: "",
      radioStatus:[
        { item: '0  ', name: '正常' },
        { item: '1', name: '異常' },
      ],

    }
  },

  mounted() {
    this.errorMessage=''
    this.allItems=[]
    this.isShow=false
    this.isShowAll=false
    api.post('/api/vendor-api/list', {}).then (res => {

      //console.log(res.data)
      let status =res.data.statusCode
      let result =res.data.body.result

      if (status===200 && result==='success'){
        this.nameOptions = res.data.body.data['nameOptions']
        this.vendorOptions = res.data.body.data['vendorOptions']
        this.allItems = res.data.body.data['allItems']
        this.totalRows = this.allItems.length

      }
      if (status===200 && result==='failure'){
        this.errorMessage=res.data.body.error
        this.messageColor="warning"

      }
    })
        .catch(error => {
          console.log(error)
          this.errorMessage=error
        })

  },
  methods: {
    initDetailItem() {
      this.errorMessage = ""
      this.lIndex = 0
    },

    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
      this.initDetailItem()
      this.$nextTick(() => {
        this.detailItem = {}
      })

    },
    onRowSelected(item, index) {
      let oldItem = {}
      this.selected = item
      this.initDetailItem()
      this.formTittle = '更新'
      oldItem = this.allItems.find(v => v.id === item.id)
      this.detailItem ={
        name: oldItem.name,
        vendor: oldItem.vendor,
        key : oldItem.key,
        code: oldItem.code,
        status: oldItem.status

      }
      this.isShow = true
      this.lIndex = index
      this.isShowAll=true

      this.$nextTick(() => this.$refs.name.focus())

    },
    addDetail() {
      this.errorMessage = ""
      this.isShow = true
      this.isShowAll=true
      this.formTittle = '登録'
      this.detailItem = {
        name: null,
        vendor: null,
        key: "",
        code: "",
        status: "0",
      }

      this.$nextTick(() => {
        this.$refs.name.focus()
        this.$refs.selectableTable.clearSelected()
      })


    },
    register() {
      this.initDetailItem()
      let newItem = {}
      event.preventDefault()
      api.post('/api/vendor-api/add', {
        name: this.detailItem.name,
        vendor: this.detailItem.vendor,
        key: this.detailItem.key,
        code: this.detailItem.code,
        status: this.detailItem.status
      }).then (res => {

        //console.log(res.data);
        let status =res.data.statusCode
        let result =res.data.body.result

        if (status===200 && result==='success'){
         this.$nextTick(() => {
           newItem = res.data.body.data.detailItem
           this.allItems.push(newItem)
           this.totalRows = this.allItems.length
           this.errorMessage = "正常に登録しました。"
           this.messageColor = "success"
           this.isShow = true
           this.isShowAll=true

        })
        }
        if (status===200 && result==='failure'){
          this.errorMessage=res.data.body.error
          this.isShow = true
          this.isShowAll=true
          this.messageColor="warning"
        }
    })
    .catch(error => {
      console.log(error)
      this.errorMessage=error
      this.messageColor="danger"
      this.isShow = false
      this.isShowAll=false
    })
    },

    apiUpdate() {

      let newItem={}
      this.initDetailItem()
      const id = this.selected.id
      event.preventDefault()
      api.put('/api/vendor-api/update/' + id, {

        name: this.detailItem.name,
        vendor: this.detailItem.vendor,
        key: this.detailItem.key,
        code: this.detailItem.code,
        status: this.detailItem.status,

      }).then (res => {

        //console.log(res.data)
        let status =res.data.statusCode
        let result =res.data.body.result

        if (status===200 && result==='success'){
          this.$nextTick(() => {
            newItem = res.data.body.data.detailItem
            const index = this.allItems.findIndex(v => v.id === newItem.id)
            this.allItems[index]['name']=newItem.name
            this.allItems[index]['vendor']=newItem.vendor
            this.allItems[index]['key']=newItem.key
            this.allItems[index]['status']=newItem.status
            this.allItems[index]['code']=newItem.code
            this.isShow = true
            this.isShowAll=true
            this.errorMessage = "正常に更新しました。"
            this.messageColor = "success"

      })
      }
          if (status===200 && result==='failure'){
            this.errorMessage=res.data.body.error
            this.isShow = true
            this.isShowAll=true
            this.messageColor="warning"
          }
        })
        .catch(error => {
          console.log(error)
          this.errorMessage=error
          this.messageColor="danger"
          this.isShow = false
          this.isShowAll=false
        })
    },

    onSubmit() {
      if (this.formTittle === "登録") {
        this.register()
      } else {
        this.apiUpdate()
      }

    },

    dataDelete() {
      let items = []
      let deleteId = this.selected.id
      this.$confirm("「確定」をクリックすると、削除したレコードを元に戻すことはできません。これのレコードを削除してもよろしいですか?", "").then(()=>{
        api.delete('/api/vendor-api/delete/' + deleteId, {}).then (res => {

            //console.log(res.data)
            let status =res.data.statusCode
            let result =res.data.body.result

            if (status===200 && result==='success'){
              items = this.allItems.filter((item) => {
                return item.id !== deleteId
              })
              this.allItems=items
              this.totalRows = this.allItems.length
              this.isShow=false
              this.isShowAll=true
              this.errorMessage="正常に削除しました。"
              this.messageColor="success"

            }
            if (status===200 && result==='failure'){
              this.errorMessage=res.data.body.error
              this.messageColor="warning"
              this.isShow = false
              this.isShowAll=true
            }
          })
      .catch(error => {
        console.log(error)
        this.errorMessage=error
        this.messageColor="danger"
        this.isShow=false
        this.isShowAll=true
      })

      })

    },

  },
}
</script>

<style src="./ApiRegister.scss" lang="scss" scoped />
