
<template>
  <div class="tables-basic">
    <b-breadcrumb>
      <b-breadcrumb-item>Machine-Learning Exclusions</b-breadcrumb-item>
      <b-breadcrumb-item active>Machine-Learning Exclusions</b-breadcrumb-item>
    </b-breadcrumb>
    <b-row>
      <b-col>
        <Widget class="h-100 mb-0" title="">
          <div class="pb-xlg h-100">
            <b-row align-h="end" >
              <b-col>
                <div class="form-group">
                  <b-form-group
                      label-for="select"
                  >
                    <b-form-select v-model="customerSearchKey">
                      <option :value="null">-- CUSTOMER --</option>
                      <option
                          v-for="(option, idx) in customerOptions"
                          :key="idx"
                          :value="option"
                      >
                        {{ option }}
                      </option>
                    </b-form-select>
                  </b-form-group>
                </div>
              </b-col>
              <b-col>
                <div class="form-group">
                  <b-form-group
                      label-for="select"
                  >
                    <b-form-select v-model="valueSearchKey">
                      <option :value="null">-- EXCLUSION PATTERNS --</option>
                      <option
                          v-for="(option, idx) in valueOptions"
                          :key="idx"
                          :value="option"
                      >{{ option }}
                      </option>
                    </b-form-select>
                  </b-form-group>
                </div>
              </b-col>
              <b-col>
                <div class="form-group">
                  <b-form-group
                      label-for="select"
                  >
                    <b-form-select v-model="detectionsSearchKey">
                      <option :value="null">-- Detections and preventions --</option>
                      <option
                          v-for="(option, idx) in detectionsOptions"
                          :key="idx"
                          :value="option"
                      >{{ option }}
                      </option>
                    </b-form-select>
                  </b-form-group>
                </div>
              </b-col>
              <b-col>
                <div class="form-group">
                  <b-form-group
                      label-for="select"
                  >
                    <b-form-select v-model="uploadSearchKey">
                      <option :value="null">-- Uploads --</option>
                      <option
                          v-for="(option, idx) in uploadOptions"
                          :key="idx"
                          :value="option"
                      >{{ option }}
                      </option>
                    </b-form-select>
                  </b-form-group>
                </div>
              </b-col>
            <b-col ><div class="form-group">
              <b-form-group
                  label=""
                  label-for="per-page-select"
                  label-align="left"
              >
                <b-form-select
                    id="per-page-select"
                    v-model="perPage"
                    :options="pageOptions"
                    @change="initDetailItem"

                ></b-form-select>
              </b-form-group></div>
            </b-col>
            </b-row>

          <b-table
              style="width:100%"
              :items="allItems"
              :fields="visibleFields"
              :current-page="currentPage"
              :per-page="perPage"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :filter="filter"
              :filter-function="tableFilter"
              show-empty
              responsive="sm"
              @filtered="onFiltered"
              :select-mode="selectMode"
              selectable
              outlined
              head-variant="light"
              selected-variant="success"
              @row-clicked="onRowSelected"
              primary-key="url"
          >
            <template #cell(index)="row">
              {{ row.index + 1 }}
            </template>
            <template #cell(selected)="{ rowSelected }">
              <template v-if="rowSelected">
                <span style="color:#5c1515;" aria-hidden="true">&check;</span>
                <span class="sr-only">Selected</span>
              </template>
              <template v-else>
                <span aria-hidden="true">&nbsp;</span>
                <span class="sr-only">Not selected</span>
              </template>
            </template>

            <template #cell(vendor)="row">
              <template v-if="row.value.length > 20">
                <span  v-b-popover.hover.focus="row.value">{{row.value.substring(0,20)}}...</span>
              </template>
              <template v-else>
                <span >{{row.value}}</span>
              </template>
            </template>
            <template #cell(customer_name)="row">
              <template v-if="row.value.length > 20">
                <span  v-b-popover.hover.focus="row.value">{{row.value.substring(0,20)}}...</span>
              </template>
              <template v-else>
                <span >{{row.value}}</span>
              </template>
            </template>
            <template #cell(pattern_name)="row">
              <template v-if="row.value.length > 60">
                <span  v-b-popover.hover.focus="row.value">{{row.value.substring(0,60)}}...</span>
              </template>
              <template v-else>
                <span >{{row.value}}</span>
              </template>
            </template>
            <template #cell(detections)="row">
              <template v-if="row.value.length > 100">
                <span v-b-popover.hover.focus="row.value">{{ row.value.substring(0, 40) }}...</span>
              </template>
              <template v-else>
                <span>{{ row.value }}</span>
              </template>
            </template>
            <template #cell(upload)="row">
              {{ row.value }}
            </template>

           </b-table>

          <b-row>
            <download-excel
                :data="formattedDataCsv"
                :fields="json_fields"
                class="btn btn-default"
                name="ml_exclusion_list.csv"
                type="csv"
                worksheet="ml_exclusion"
            >
              <el-button plain type="primary">ダンロード<i class="el-icon-download el-icon--right"></i></el-button>
            </download-excel>
            <b-col></b-col>
            <b-col  class="my-0">
              <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  align="left"
                  size="md"
                  class="my-0"
                  @page-click="initDetailItem"
              ></b-pagination>
            </b-col>
          </b-row>

        </div>
      </Widget>
    </b-col>
  </b-row>
  </div>
</template>

<script>
import Widget from '@/components/Widget/Widget'
import api from "@/api"

export default {
  name: 'MlExclusions',
  components: {Widget},
  computed: {

    formattedDataCsv() {
      return this.allItems.map(item => {
        return {
          customer_name: item.customer_name,
          value: item.value,
          detections: item.detections,
          upload: item.upload,
          host_groups: item.host_groups,
          last_modified: item.last_modified,
        }
      })
    },
    visibleFields() {
      return this.fields.filter(field => field.visible)
    },

    filter: function () {
      return [this.customerSearchKey, this.valueSearchKey, this.detectionsSearchKey, this.uploadSearchKey]
    }

  },
  data() {
    return {
      mailHref: '',
      mailMessage: '',
      mailStyle:'display: none',
      eMailTo:[],
      lIndex:0,
      messageColor:'',
      errorMessage:'',
      selectMode: 'single',//'multi'
      selected: {},
      statusColor: ['warning', 'info', 'success', 'primary', 'secondary'],
      aResultText: ['Low', 'High'],
      aResultColor: ['warning', 'danger'],
      sortBy: 'id',
      sortDesc: true,
      allItems: [],
      valueSearchKey: null,
      valueOptions: [],
      customerSearchKey: null,
      customerOptions: [],
      detectionsSearchKey: null,
      detectionsOptions: [],
      uploadSearchKey: null,
      uploadOptions: [],
      json_fields: {
        'Customer': 'customer_name',
        'Exclusion Patterns': 'value',
        'Detections and preventions': 'detections',
        'Uploads to CrowdStrike': 'upload',
        'Active Hosts': 'host_groups',
        'last_modified': 'last_modified',
      },
      fields: [
        {key: 'id', label: 'No.', sortable: true, class: 'text-center', visible: false,},//PK
        {key: 'customer_name', label: 'Customer', sortable: true, class: 'text-left', visible: true},
        {key: 'value', label: 'Exclusion Patterns', sortable: true, class: 'text-left', visible: true},
        {key: 'detections', label: 'Detections and preventions', sortable: true, class: 'text-left', visible: true},
        {key: 'upload', label: 'Uploads to CrowdStrike', sortable: true, class: 'text-left', visible: true},
        {key: 'host_groups', label: 'Active hosts', sortable: true, class: 'text-left', visible: true},
        {key: 'last_modified', label: 'last_modified', sortable: true, class: 'text-left', visible: true},
      ],

      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 20, 30, {value: 100, text: "Show a lot"}],
      isShow:false,
      detailItem:{},
      selectOwnerList:[],
    }
  },
  mounted() {
    this.errorMessage=''
    this.allItems=[]
    this.isShow=false
    this.vendorOptions=[]
    this.errorMessage=''
    this.totalRows = this.allItems.length

    api.post('/api/ml_exclusions/list', {}).then (res => {

        let status =res.data.statusCode
        let result =res.data.body.result
        if (status===200 && result==='success') {
          this.allItems = res.data.body.data['allItems']
          this.customerOptions = res.data.body.data['customerOptions']
          this.valueOptions = res.data.body.data['valueOptions']
          this.detectionsOptions = res.data.body.data['excludedOptions']
          this.uploadOptions = res.data.body.data['appliedOptions']
          this.totalRows = this.allItems.length
          console.log(res.data.body.data)

        }
        if (status===200 && result==='failure'){
          this.errorMessage=res.data.body.error
          this.messageColor="warning"

        }
    })
        .catch(error => {
          console.log(error);
          this.errorMessage=error;
        })

  },

  methods: {

    initDetailItem(){
      this.detailItem ={}
      this.isShow = false
      this.errorMessage=""
      this.lIndex=0

    },


    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
      this.errorMessage=''
      this.initDetailItem()
      this.detailItem = {}

    },
    onRowSelected (selectedRows) {
      this.selected = selectedRows
      this.detailItem = selectedRows[0]
      this.isShow = true
      this.errorMessage=""
      this.lIndex=0
    },
    tableFilter(row) {
      let filter_customer_name = this.customerSearchKey ? String(row.customer_name).toLocaleLowerCase() === String(this.customerSearchKey).toLocaleLowerCase() : true
      let filter_value = this.valueSearchKey ? String(row.value).toLocaleLowerCase() === String(this.valueSearchKey).toLocaleLowerCase() : true
      let filter_detections = this.detectionsSearchKey ? String(row.detections).toLocaleLowerCase() === String(this.detectionsSearchKey).toLocaleLowerCase() : true
      let filter_upload = this.uploadSearchKey ? String(row.upload).toLocaleLowerCase() === String(this.uploadSearchKey).toLocaleLowerCase() : true
      return filter_customer_name && filter_value && filter_detections && filter_upload
    },
  },
}
</script>

<style src="./IoaExclusion.scss" lang="scss" scoped />