<template>
  <div class="login-page">
    <b-container>
      <h1 class="logo">
                <span class="avatar thumb  mr-2">
                    <img alt="..." class="rounded-right " src="../../assets/logo/logo_corp.svg"/>
                </span>
        <span style="color:#01ab86">セキュアエッジ </span>
      </h1>
      <Widget name="login" class="mx-auto" title="<h3 class='mt-0 center'></h3>" customHeader>
        <div class="clearfix">
          <div class="clearfix">
            <h4 class="center">パスワードを忘れた方</h4>
          </div>
          <p class="mb-2 px-2">
            登録したメールアドレスを下記フォームへ入力し「確認メールを送信」ボタンを押してください。<br>
            パスワード再設定用のメールが送信されますので、メール本文の案内に従いパスワードの再設定を行ってください。
          </p>
          <p class="mb-8 px-sm-2 text-sm text-red-500">※セキュリティ上、ご登録メールアドレス以外に連絡することはできません</p>
          <p></p>

          <p class="same-account-explanation"></p>
        </div>
        <form class="mt" @submit.prevent="onReset">
          <b-alert v-if="errorMessage!==''" :show="!!errorMessage" :variant="messageColor" class="m-8"><h4>{{ errorMessage }}</h4></b-alert>

          <div class="form-group">
            <input v-model="email" class="form-control no-border" ref="email"
                   required type="text" name="email" placeholder="メールアドレス" autocomplete="email" />
          </div>



          <div class="clearfix center">
            <b-button type="submit" size="lg" variant="success" > 確認メールを送信</b-button>
          </div>
          <br>
          <div class="clearfix center">
            <a class="btn-link" href="/">ログインページへ</a>
          </div>

        </form>
      </Widget>
    </b-container>
    <footer class="footer">
    </footer>
  </div>
</template>

<script>

import Widget from '@/components/Widget/Widget';
import api from "@/api"

export default {
  name: 'PasswordForget',
  components: { Widget },
  data () {
    return {
      messageColor:'',
      errorMessage:'',
      email:''
    }
  },
  methods: {
    onReset: function () {
      api.post('/api/password-recover', {
        email: this.email
      }).then (res => {

        let status =res.data.statusCode
        let result =res.data.body.result

        if (status===200 && result==='success'){

          this.errorMessage="ご指定されるメールアドレスにパスワードの再設定情報を送付したので、ご確認ください。"
          this.messageColor="success"

        }
        if (status===200 && result==='failure'){
          this.errorMessage=res.data.body.error
          this.messageColor="warning"
        }
      })
          .catch(error => {
            console.log(error)
            this.errorMessage=error
            this.messageColor="danger"
          })
    }
  }
}
</script>

<style src="./Login.scss" lang="scss" scoped />