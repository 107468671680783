<template>
  <div class="tables-basic">
    <b-breadcrumb>
      <b-breadcrumb-item>Sensor-Visibility Exclusions</b-breadcrumb-item>
      <b-breadcrumb-item active>Sensor-Visibility Exclusions</b-breadcrumb-item>
    </b-breadcrumb>
    <b-row>
      <b-col>
        <Widget class="h-100 mb-0" title="">
          <div class="pb-xlg h-100">
            <b-row align-h="end">
              <b-col>
                <div class="form-group">
                  <b-form-group
                      label-for="select"
                  >
                    <b-form-select v-model="customerSearchKey">
                      <option :value="null">-- CUSTOMER --</option>
                      <option
                          v-for="(option, idx) in customerOptions"
                          :key="idx"
                          :value="option"
                      >
                        {{ option }}
                      </option>
                    </b-form-select>
                  </b-form-group>
                </div>
              </b-col>

              <b-col>
                <div class="form-group">
                  <b-form-group
                      label-for="select"
                  >
                    <b-form-select v-model="excludedSearchKey">
                      <option :value="null">-- EXCLUSION PATTERNS --</option>
                      <option
                          v-for="(option, idx) in excludedOptions"
                          :key="idx"
                          :value="option"
                      >{{ option }}
                      </option>
                    </b-form-select>
                  </b-form-group>
                </div>
              </b-col>
              <b-col>
                <div class="form-group">
                  <b-form-group
                      label=""
                      label-align="left"
                      label-for="per-page-select"

                  >
                    <b-form-select
                        id="per-page-select"
                        v-model="perPage"
                        :options="pageOptions"
                        @change="initDetailItem"

                    ></b-form-select>
                  </b-form-group>
                </div>
              </b-col>
            </b-row>

            <b-table
                :current-page="currentPage"
                :fields="visibleFields"
                :items="allItems"
                :per-page="perPage"
                :select-mode="selectMode"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter="filter"
                :filter-function="tableFilter"
                head-variant="light"
                outlined
                primary-key="url"
                responsive="sm"
                selectable
                selected-variant="success"
                show-empty
                style="width:100%"
                @filtered="onFiltered"
                @row-clicked="onRowSelected"

            >
              <template #cell(index)="row">
                {{ row.index + 1 }}
              </template>
              <template #cell(selected)="{ rowSelected }">
                <template v-if="rowSelected">
                  <span aria-hidden="true" style="color:#5c1515;">&check;</span>
                  <span class="sr-only">Selected</span>
                </template>
                <template v-else>
                  <span aria-hidden="true">&nbsp;</span>
                  <span class="sr-only">Not selected</span>
                </template>
              </template>

              <template #cell(vendor)="row">
                <template v-if="row.value.length > 20">
                  <span v-b-popover.hover.focus="row.value">{{ row.value.substring(0, 20) }}...</span>
                </template>
                <template v-else>
                  <span>{{ row.value }}</span>
                </template>
              </template>
              <template #cell(customer_name)="row">
                <template v-if="row.value.length > 20">
                  <span v-b-popover.hover.focus="row.value">{{ row.value.substring(0, 20) }}...</span>
                </template>
                <template v-else>
                  <span>{{ row.value }}</span>
                </template>
              </template>
              <template #cell(name)="row">
                <template v-if="row.value.length > 40">
                  <span v-b-popover.hover.focus="row.value">{{ row.value.substring(0, 40) }}...</span>
                </template>
                <template v-else>
                  <span>{{ row.value }}</span>
                </template>
              </template>
              <template #cell(pattern_name)="row">
                <template v-if="row.value.length > 60">
                  <span v-b-popover.hover.focus="row.value">{{ row.value.substring(0, 60) }}...</span>
                </template>
                <template v-else>
                  <span>{{ row.value }}</span>
                </template>
              </template>

            </b-table>

            <b-row>
              <download-excel
                  :data="formattedDataCsv"
                  :fields="json_fields"
                  class="btn btn-default"
                  name="sensor_visibility_exclusion_list.csv"
                  type="csv"
                  worksheet="sensor_visibility_exclusion"
              >
                <el-button plain type="primary">ダンロード<i class="el-icon-download el-icon--right"></i></el-button>
              </download-excel>
              <b-col></b-col>
              <b-col class="my-0">
                <b-pagination
                    v-model="currentPage"
                    :per-page="perPage"
                    :total-rows="totalRows"
                    align="left"
                    class="my-0"
                    size="md"
                    @page-click="initDetailItem"
                ></b-pagination>
              </b-col>
            </b-row>

          </div>
        </Widget>
      </b-col>
    </b-row>
  </div>
</template>

<script>

import Widget from '@/components/Widget/Widget'
import api from "@/api"

export default {
  name: 'SVExclusions',
  components: {Widget},
  computed: {
    formattedDataCsv() {
      return this.allItems.map(item => {
        return {
          customer_name: item.customer_name,
          regexp_value: item.regexp_value,
          hosts: item.hosts,
          last_modified: item.last_modified,
        }
      })
    },

    visibleFields() {
      return this.fields.filter(field => field.visible)
    },

    filter: function () {
      return [this.customerSearchKey,this.excludedSearchKey]
    }
  },
  data() {
    return {
      mailHref: '',
      mailMessage: '',
      mailStyle: 'display: none',
      eMailTo: [],
      lIndex: 0,
      messageColor: '',
      errorMessage: '',
      selectMode: 'single',//'multi'
      selected: {},
      statusColor: ['warning', 'info', 'success', 'primary', 'secondary'],
      aResultText: ['Low', 'High'],
      aResultColor: ['warning', 'danger'],
      sortBy: 'id',
      sortDesc: true,
      allItems: [],
      customerSearchKey: null,
      customerOptions: [],
      excludedSearchKey: null,
      excludedOptions: [],
      hostSearchKey: null,
      hostOptions: [],

      json_fields: {
        'Customer': 'customer_name',
        'Exclusion Patterns': 'regexp_value',
        'Active hosts': 'hosts',
        'last_modified': 'last_modified'
      },
      fields: [
        {key: 'id', label: 'No.', sortable: true, class: 'text-center', visible: false,},
        {key: 'customer_name', label: 'Customer', sortable: true, class: 'text-left', visible: true},
        {key: 'value', label: 'Exclusion Patterns', sortable: true, class: 'text-left', visible: true},
        {key: 'hosts', label: 'Active hosts', sortable: true, class: 'text-left', visible: true},
        {key: 'last_modified', label: 'last_modified', sortable: true, class: 'text-left', visible: true}
      ],

      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 20, 30, {value: 100, text: "Show a lot"}],
      isShow: false,
      detailItem: {},
      selectOwnerList: [],
    }
  },
  mounted() {
    this.errorMessage = ''
    this.allItems = []
    this.isShow = false
    this.vendorOptions = []
    this.nameOptions = []
    this.errorMessage = ''
    this.totalRows = this.allItems.length

    api.post('/api/sv_exclusions/list', {}).then(res => {

      let status = res.data.statusCode
      let result = res.data.body.result
      if (status === 200 && result === 'success') {
        this.allItems = res.data.body.data['allItems']
        this.customerOptions = res.data.body.data['customerOptions']
        this.hostOptions = res.data.body.data['hostOptions']
        this.excludedOptions = res.data.body.data['excludedOptions']
        this.totalRows = this.allItems.length
        console.log(res.data.body.data)

      }
      if (status === 200 && result === 'failure') {
        this.errorMessage = res.data.body.error
        this.messageColor = "warning"

      }
    })
        .catch(error => {
          console.log(error);
          this.errorMessage = error;
        })

  },

  methods: {
    tableFilter(row) {
      let filter_customer = this.customerSearchKey ? String(row.customer_name).toLocaleLowerCase() === String(this.customerSearchKey).toLocaleLowerCase() : true
      let filter_excluded = this.excludedSearchKey ? String(row.value).toLocaleLowerCase() === String(this.excludedSearchKey).toLocaleLowerCase() : true
      //let filter_status = this.statusSearchKey !=null  ? String(row.status) === String(this.statusSearchKey ): true
      return filter_customer && filter_excluded
    },

    initDetailItem() {
      this.detailItem = {}
      this.isShow = false
      this.errorMessage = ""
      this.lIndex = 0

    },


    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
      this.errorMessage = ''
      this.initDetailItem()
      this.detailItem = {}

    },

    onRowSelected(selectedRows) {
      this.selected = selectedRows
      this.detailItem = selectedRows[0]
      this.isShow = true
      this.errorMessage = ""
      this.lIndex = 0
    },


  },

}
</script>


<style lang="scss" scoped src="./IoaExclusion.scss"/>
